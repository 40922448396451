import { useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  makeStyles,
  Button,
  Theme,
  useTheme,
  Typography,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ArrowRight from "@material-ui/icons/ArrowRight";
import FilterWrapper from "./filterWrapper";
import {
  IClientPeriod,
  IPeriodHierarchyOption,
} from "../../../state/types/FilterOptions";
import PeriodPicker from "./periodPicker";
import RelativeDatePicker from "./relativeDatePicker";
import CustomDateRangePicker from "./customDateRangePicker";
import PeriodHierarchyPicker from "./periodHierarchyPicker";
import {
  VisitDateSelectionType,
  IVIsitDateRange,
} from "../../../state/types/FilterSets";
import { generateRelativeDateFromSelectionType } from "../../../utils/visitDateFilterUtils";
import { hgemColours } from "../../../themes/defaultTheme";
import { i18n } from "../../../localizations";
import {
  useCalculateTotalDaysPeriods,
  useCalculateTotalDaysPeriodHierarchies,
} from "./utils/calculateTotalDays";

interface IProps {
  dateSelectionType: VisitDateSelectionType;
  availableLegacyPeriods: IClientPeriod[];
  selectedLegacyPeriods: string[];
  selectedPeriodHierarchies: number[];
  selectedCustomRange: IVIsitDateRange | null;
  periodHierarchyOptions: IPeriodHierarchyOption[];
  expandedPeriodHierarchyNodes: string[] | null;
  applyLegacyPeriodSelectionCallback: (
    periods: string[],
    rangeType: VisitDateSelectionType
  ) => void;
  applyPeriodHeiorarchySelectionCallback: (
    periods: number[],
    rangeType: VisitDateSelectionType,
    expandedNodes: string[]
  ) => void;
  applySelectionRangeCallback: (
    dateRange: IVIsitDateRange,
    rangeType: VisitDateSelectionType
  ) => void;
}

const InitialView = "initial";
const RelativeDateView = "relative_date";
const LegacyPeriodView = "legacy_period";
const CustomDateView = "custom_date";
const PeriodHierarchyView = "period_hierarchy";
const HelpView = "help";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  subMenuArrow: {
    marginLeft: "30px",
  },
  helpContainer: {
    maxWidth: "400px",
    padding: theme.spacing(2),
  },
  helpItem: {
    marginBottom: theme.spacing(2),
  },
  actionContainer: {
    borderTop: `1px solid ${hgemColours.LightGrey}`,
    padding: theme.spacing(1),
  },
  errorContainer: {
    display: "flex",
    flexDirection: "row",
    minWidth: "100%",
    width: "min-content",
    background: theme.palette.warning.main,
    padding: theme.spacing(1),
    color: hgemColours.White,
    boxSizing: "border-box",
    alignItems: "center",
  },
  maxPeriodError: {
    fontSize: theme.typography.pxToRem(12),
    width: "100%",
  },
}));

const DateAndPeriodPicker = (props: IProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [isOpen, setIsOpen] = useState(false);
  const [currentView, setCurrentView] = useState(InitialView);
  const [showMaxPeriodError, setShowMaxPeriodError] = useState(false);

  const isRawData = location.pathname.includes("raw-data");
  const MAXIMUM_SELECTABLE_DAYS = isRawData
    ? parseInt(process.env.REACT_APP_RAW_DATA_MAXIMUM_DAYS ?? "90")
    : undefined;

  const calculateTotalDaysPeriods = useCalculateTotalDaysPeriods(
    props.availableLegacyPeriods,
    MAXIMUM_SELECTABLE_DAYS
  );

  const calculateTotalDaysPeriodHierarchies =
    useCalculateTotalDaysPeriodHierarchies(
      props.periodHierarchyOptions,
      MAXIMUM_SELECTABLE_DAYS
    );

  useEffect(() => {
    if (isRawData && MAXIMUM_SELECTABLE_DAYS) {
      if (
        props.selectedLegacyPeriods &&
        props.dateSelectionType === VisitDateSelectionType.ClientPeriods
      ) {
        const totalDays = calculateTotalDaysPeriods(
          props.selectedLegacyPeriods
        );

        if (totalDays > MAXIMUM_SELECTABLE_DAYS) {
          const newSelectedPeriods = [];
          for (const period of props.selectedLegacyPeriods) {
            if (
              calculateTotalDaysPeriods([...newSelectedPeriods, period]) <=
              MAXIMUM_SELECTABLE_DAYS!
            ) {
              newSelectedPeriods.push(period);
            } else {
              break;
            }
          }
          props.applyLegacyPeriodSelectionCallback(
            newSelectedPeriods,
            VisitDateSelectionType.ClientPeriods
          );
        }
      }

      if (
        props.selectedPeriodHierarchies &&
        props.dateSelectionType === VisitDateSelectionType.reportingPeriods
      ) {
        const totalDays = calculateTotalDaysPeriodHierarchies(
          props.selectedPeriodHierarchies
        );

        if (totalDays > MAXIMUM_SELECTABLE_DAYS) {
          const newSelectedPeriods: number[] = [];
          for (const period of props.selectedPeriodHierarchies.sort(
            (a, b) => b - a
          )) {
            if (
              calculateTotalDaysPeriodHierarchies([
                ...newSelectedPeriods,
                period,
              ]) <= MAXIMUM_SELECTABLE_DAYS!
            ) {
              newSelectedPeriods.push(period);
            } else {
              break;
            }
          }
          props.applyPeriodHeiorarchySelectionCallback(
            newSelectedPeriods,
            VisitDateSelectionType.reportingPeriods,
            []
          );
        }
      }

      if (
        props.selectedCustomRange &&
        props.dateSelectionType === VisitDateSelectionType.Custom
      ) {
        const { dateStart, dateEnd } = props.selectedCustomRange;
        const totalDays = dayjs(dateEnd).diff(dayjs(dateStart));
        if (totalDays > MAXIMUM_SELECTABLE_DAYS) {
          const newStartDate = dayjs(dateEnd)
            .subtract(MAXIMUM_SELECTABLE_DAYS, "day")
            .toDate();
          props.applySelectionRangeCallback(
            { dateStart: newStartDate, dateEnd: dateEnd },
            VisitDateSelectionType.Custom
          );
        }
      }
    }

    const visitDateRangeNeedsUpdating =
      props.dateSelectionType === VisitDateSelectionType.LastFourWeeks ||
      props.dateSelectionType === VisitDateSelectionType.LastTwoWeeks ||
      props.dateSelectionType === VisitDateSelectionType.LastTwelveWeeks ||
      props.dateSelectionType === VisitDateSelectionType.LastWeek;

    if (visitDateRangeNeedsUpdating) {
      applyRelativeRangeSelection(props.dateSelectionType, false);
    }
  }, []);

  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
    setCurrentView(InitialView);
  };

  const switchView = (view: string) => {
    setCurrentView(view);
  };

  const getAppliedSelectionText = (): string => {
    let selectionText = "";

    switch (props.dateSelectionType) {
      case VisitDateSelectionType.Custom:
        selectionText = i18n.translate("DATE_PERIOD_PICKER_Custom_Date_Range");
        break;
      case VisitDateSelectionType.ClientPeriods:
        selectionText = `${props.selectedLegacyPeriods.length} ${i18n.translate(
          "DATE_PERIOD_PICKER_Num_Periods_Selected"
        )}`;
        break;
      case VisitDateSelectionType.reportingPeriods:
        selectionText = `${
          props.selectedPeriodHierarchies.length
        } ${i18n.translate(
          "DATE_PERIOD_PICKER_Num_Reporting_Periods_Selected"
        )}`;
        break;
      default:
        selectionText = i18n.translate(
          `DATE_PERIOD_PICKER_${VisitDateSelectionType[
            props.dateSelectionType
          ].toString()}`
        );
        break;
    }

    return selectionText;
  };

  const buildTooltipContent = (): string => {
    let tooltipText = "";
    const dateRange = generateRelativeDateFromSelectionType(
      props.dateSelectionType
    );

    switch (props.dateSelectionType) {
      case VisitDateSelectionType.Custom:
        tooltipText = getTooltipFormatedDateRangeValue(
          props.selectedCustomRange?.dateStart,
          props.selectedCustomRange?.dateEnd
        );
        break;
      case VisitDateSelectionType.LastWeek:
      case VisitDateSelectionType.LastTwoWeeks:
      case VisitDateSelectionType.LastFourWeeks:
      case VisitDateSelectionType.LastTwelveWeeks:
        tooltipText = getTooltipFormatedDateRangeValue(
          dateRange.dateStart,
          dateRange.dateEnd
        );
        break;
      default:
        tooltipText = getTooltipFormattedLegacyPeriodRangeValue(
          props.selectedLegacyPeriods
        );
        break;
    }

    return tooltipText;
  };

  const getTooltipFormatedDateRangeValue = (
    dateStart: Date | undefined,
    dateEnd: Date | undefined
  ): string => {
    if (dateStart && dateEnd) {
      return `${dayjs(dateStart).format(
        i18n.translate("DATE_PERIOD_PICKER_Tooltip_Date_Format")
      )} -> ${dayjs(dateEnd).format(
        i18n.translate("DATE_PERIOD_PICKER_Tooltip_Date_Format")
      )}`;
    } else {
      return "";
    }
  };

  const getTooltipFormattedLegacyPeriodRangeValue = (
    periods: string[]
  ): string => {
    if (periods && periods.length > 0) {
      const tooltipText = `${periods.length} ${i18n.translate(
        "DATE_PERIOD_PICKER_Periods_Selected"
      )} `;
      let periodsList = periods.join(", ");

      if (periodsList.length > 150) {
        periodsList = periodsList.substring(0, 150) + "...";
      }

      return tooltipText + periodsList;
    } else {
      return "";
    }
  };

  const applyRelativeRangeSelection = (
    rangeType: VisitDateSelectionType,
    shouldOpenSelector = true
  ): void => {
    const dateRange = generateRelativeDateFromSelectionType(rangeType);
    applyDateRangeSelection(
      dateRange.dateStart,
      dateRange.dateEnd,
      rangeType,
      shouldOpenSelector
    );
  };

  const applyDateRangeSelection = (
    dateStart: Date,
    dateEnd: Date,
    rangeType: VisitDateSelectionType = VisitDateSelectionType.Custom,
    shouldOpenSelector = true
  ): void => {
    props.applySelectionRangeCallback({ dateStart, dateEnd }, rangeType);
    if (shouldOpenSelector) {
      handleToggleOpen();
    }
  };

  const applyLegacyPeriodSelection = (
    periods: string[],
    selectionType: VisitDateSelectionType
  ): void => {
    let selectedPeriods = periods;
    if (periods.length === 0) {
      selectedPeriods = props.availableLegacyPeriods.map((p) => p.periodName);
    }

    props.applyLegacyPeriodSelectionCallback(selectedPeriods, selectionType);
    handleToggleOpen();
  };

  const applyPeriodHierarchySelection = (
    periods: number[],
    selectionType: VisitDateSelectionType,
    expandedNodes: string[]
  ): void => {
    props.applyPeriodHeiorarchySelectionCallback(
      periods,
      selectionType,
      expandedNodes
    );
    handleToggleOpen();
  };

  return (
    <FilterWrapper
      label={i18n.translate("DATE_PERIOD_PICKER_Dates")}
      selectionHint={getAppliedSelectionText()}
      isOpen={isOpen}
      toggleOpenState={handleToggleOpen}
      tooltip={buildTooltipContent()}
    >
      {showMaxPeriodError && (
        <div className={classes.errorContainer}>
          <div className={classes.maxPeriodError}>
            {i18n.translate("DATE_PERIOD_PICKER_Max_Period_Error")}
          </div>
          <IconButton
            size="small"
            style={{ color: "white" }}
            title={i18n.translate("DATE_PERIOD_PICKER_Close_Warning")}
            onClick={() => setShowMaxPeriodError(false)}
          >
            <CloseIcon />
          </IconButton>
        </div>
      )}

      {currentView === InitialView && (
        <>
          <List component="nav" dense>
            {props.periodHierarchyOptions.length > 0 && (
              <ListItem button onClick={() => switchView(PeriodHierarchyView)}>
                <ListItemText
                  primary={i18n.translate(
                    "DATE_PERIOD_PICKER_Reporting_Period"
                  )}
                />
                <ListItemIcon>
                  <ArrowRight className={classes.subMenuArrow} />
                </ListItemIcon>
              </ListItem>
            )}

            <ListItem button onClick={() => switchView(LegacyPeriodView)}>
              <ListItemText
                primary={i18n.translate("DATE_PERIOD_PICKER_Visit_Schedules")}
              />
              <ListItemIcon>
                <ArrowRight className={classes.subMenuArrow} />
              </ListItemIcon>
            </ListItem>

            <ListItem button onClick={() => switchView(RelativeDateView)}>
              <ListItemText
                primary={i18n.translate("DATE_PERIOD_PICKER_RelativeDate")}
              />
              <ListItemIcon>
                <ArrowRight className={classes.subMenuArrow} />
              </ListItemIcon>
            </ListItem>

            <ListItem button onClick={() => switchView(CustomDateView)}>
              <ListItemText
                primary={i18n.translate("DATE_PERIOD_PICKER_Custom_Date_Range")}
              />
              <ListItemIcon>
                <ArrowRight className={classes.subMenuArrow} />
              </ListItemIcon>
            </ListItem>
          </List>
          <div className={classes.actionContainer}>
            <Button variant="text" onClick={() => switchView(HelpView)}>
              {i18n.translate("DATE_PERIOD_PICKER_Which_do_I_Choose")}
            </Button>
          </div>
        </>
      )}

      {currentView === RelativeDateView && (
        <RelativeDatePicker
          onCancel={() => switchView(InitialView)}
          onApply={applyRelativeRangeSelection}
        />
      )}

      {currentView === LegacyPeriodView && (
        <PeriodPicker
          availablePeriods={props.availableLegacyPeriods}
          selectedPeriods={props.selectedLegacyPeriods ?? []}
          onCancel={() => switchView(InitialView)}
          onApply={applyLegacyPeriodSelection}
          maxDays={MAXIMUM_SELECTABLE_DAYS}
          setShowMaxPeriodError={setShowMaxPeriodError}
        />
      )}

      {currentView === PeriodHierarchyView && (
        <PeriodHierarchyPicker
          availablePeriods={props.periodHierarchyOptions}
          selectedPeriods={props.selectedPeriodHierarchies ?? []}
          expandedPeriods={props.expandedPeriodHierarchyNodes}
          onCancel={() => switchView(InitialView)}
          onApply={applyPeriodHierarchySelection}
          maxDays={MAXIMUM_SELECTABLE_DAYS}
          setShowMaxPeriodError={setShowMaxPeriodError}
        />
      )}

      {currentView === CustomDateView && (
        <CustomDateRangePicker
          defaultStartDateOffset={-14}
          endDate={props.selectedCustomRange?.dateEnd}
          startDate={props.selectedCustomRange?.dateStart}
          minDateDayOffset={-730}
          onCancel={() => switchView(InitialView)}
          onApply={applyDateRangeSelection}
          maxDays={MAXIMUM_SELECTABLE_DAYS}
          setShowMaxPeriodError={setShowMaxPeriodError}
        />
      )}

      {currentView === HelpView && (
        <div className={classes.helpContainer}>
          <div className={classes.helpItem}>
            <Typography variant="subtitle2">
              {i18n.translate("DATE_PERIOD_PICKER_HELP_ReportingPeriod_Title")}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {i18n.translate(
                "DATE_PERIOD_PICKER_HELP_ReportingPeriod_Description"
              )}
            </Typography>
          </div>
          <div className={classes.helpItem}>
            <Typography variant="subtitle2">
              {i18n.translate("DATE_PERIOD_PICKER_HELP_VisitScheule_Title")}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {i18n.translate(
                "DATE_PERIOD_PICKER_HELP_VisitScheule_Description"
              )}
            </Typography>
          </div>
          <div className={classes.helpItem}>
            <Typography variant="subtitle2">
              {i18n.translate("DATE_PERIOD_PICKER_HELP_Relative_Title")}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {i18n.translate("DATE_PERIOD_PICKER_HELP_Relative_Description")}
            </Typography>
          </div>
          <div className={classes.helpItem}>
            <Typography variant="subtitle2">
              {i18n.translate("DATE_PERIOD_PICKER_HELP_Custom_Title")}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {i18n.translate("DATE_PERIOD_PICKER_HELP_Custom_Description")}
            </Typography>
          </div>
          <Button variant="contained" onClick={() => switchView(InitialView)}>
            {i18n.translate("DATE_PERIOD_PICKER_Back")}
          </Button>
        </div>
      )}
    </FilterWrapper>
  );
};

export default DateAndPeriodPicker;
