import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  FormControlLabel,
  Checkbox,
  Grid,
  useMediaQuery,
  useTheme,
  Icon,
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import { useDispatch } from "react-redux";
import { changeFilterSetFilterVisibility } from "../../../state/actions/FilterSets-Actions";
import {
  HubFilterConfigurationOptionGrouping,
  IHubFilter,
  HubFilterType,
  FilterVisibilityBehaviour,
  IFilterSet,
  AnalysisTagKeys,
} from "../../../state/types/FilterSets";
import { FilterConfigurationOptionsStyles } from "../styles/filterConfigurationOptionsStyles";
import { i18n } from "../../../localizations";
import { hgemColours } from "../../../themes/defaultTheme";

interface IProps {
  filters: IHubFilter[];
  filterSet: IFilterSet;
  availableFilters: IHubFilter[];
  questionnaireTags?: string[];
}

const OtherFiltersQuestionnaireTags: string[] = [
  AnalysisTagKeys.VisitAnalysisRoom.toLowerCase(),
  AnalysisTagKeys.VisitAnalysisDepartment.toLowerCase(),
  AnalysisTagKeys.LengthOfService.toLowerCase(),
];

const FilterConfigurationOptions = (props: IProps) => {
  const classes = FilterConfigurationOptionsStyles();

  const dispatch = useDispatch();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([] as string[]);
  const theme = useTheme();

  const isMobileView = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.xs)
  );

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const handleApply = () => {
    dispatch(
      changeFilterSetFilterVisibility(props.filterSet.name, selectedFilters)
    );
    closeDialog();
  };

  const handleCancel = () => {
    if (props.filterSet.filters) {
      const selectedFilters: string[] = [];
      props.filterSet.filters.forEach((f) => {
        if (
          f.userSetVisibility &&
          f.userSetVisibility === FilterVisibilityBehaviour.Visible
        ) {
          selectedFilters.push(f.type);
        }
      });

      setSelectedFilters(selectedFilters);
    }
    closeDialog();
  };

  const filterIsChecked = (filterType: HubFilterType) => {
    return selectedFilters.indexOf(filterType) > -1;
  };

  const setFilterChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFilter = event.target.name;

    const selected = selectedFilters.filter((f) => {
      return f !== selectedFilter;
    });

    if (event.target.checked) {
      selected.push(selectedFilter);
    }

    setSelectedFilters(selected);
  };

  useEffect(() => {
    if (props.filterSet.filters) {
      const selectedFilters: string[] = [];
      props.filterSet.filters.forEach((f) => {
        if (
          f.userSetVisibility &&
          f.userSetVisibility === FilterVisibilityBehaviour.Visible
        ) {
          selectedFilters.push(f.type);
        }
      });

      setSelectedFilters(selectedFilters);
    }
  }, [props.filterSet.filters]);

  const filterGrouping = new HubFilterConfigurationOptionGrouping();

  const buildFilterSelection = (filterType: HubFilterType) => {
    const filterIsAvailable =
      props.availableFilters.find((x) => x.type === filterType) !== undefined;
    return (
      <FormControlLabel
        label={i18n.translate(
          `FILTER_CONFIGURATION_OPTIONS_ENUM_${HubFilterType[filterType]}`
        )}
        control={
          <Checkbox
            name={filterType.toString()}
            className={classes.checkbox}
            checked={filterIsChecked(filterType)}
            onChange={setFilterChecked}
            disabled={!filterIsAvailable}
          />
        }
      />
    );
  };

  const filterIsSelectable = (type: HubFilterType): boolean => {
    let isSelectable: boolean | undefined = true;

    const tagsExistForTagKey = (tagKey: AnalysisTagKeys) => {
      return props.questionnaireTags?.some(
        (x) => x.toLowerCase() === tagKey.toLowerCase()
      );
    };

    switch (type) {
      case HubFilterType.FeedbackCategory:
        isSelectable = tagsExistForTagKey(AnalysisTagKeys.FeedbackCategory);
        break;
      case HubFilterType.FeedbackSubCategory:
        isSelectable = tagsExistForTagKey(AnalysisTagKeys.FeedbackSubCategory);
        break;
      case HubFilterType.VisitAnalysisRoom:
        isSelectable = tagsExistForTagKey(AnalysisTagKeys.VisitAnalysisRoom);
        break;
      case HubFilterType.VisitAnalysisDepartment:
        isSelectable = tagsExistForTagKey(
          AnalysisTagKeys.VisitAnalysisDepartment
        );
        break;
      case HubFilterType.LengthOfService:
        isSelectable = tagsExistForTagKey(AnalysisTagKeys.LengthOfService);
        break;
    }

    return isSelectable ?? false;
  };

  const showOtherFiltersSection =
    props.questionnaireTags &&
    props.questionnaireTags.some(
      (x) => OtherFiltersQuestionnaireTags.indexOf(x.toLowerCase()) > -1
    );

  return (
    <>
      <Button
        className={classes.filterButton}
        variant="contained"
        color="primary"
        startIcon={<FilterListIcon />}
        onClick={openDialog}
      >
        {!isMobileView &&
          i18n.translate("FILTER_CONFIGURATION_OPTIONS_Add_Filter")}
        {isMobileView && (
          <Icon
            fontSize="small"
            className={`fas fa-plus`}
            style={{ color: hgemColours.White }}
          />
        )}
      </Button>

      <Dialog onClose={closeDialog} open={dialogOpen}>
        <DialogContent>
          <div className={classes.filterGroup}>
            <Typography>
              {i18n.translate(
                "FILTER_CONFIGURATION_OPTIONS_Questions_and_Answers"
              )}
            </Typography>
            <Grid container>
              {filterGrouping.QuestionsAndAnswers.map((f) => {
                return (
                  <Grid item xs={12} md={6} key={f}>
                    {buildFilterSelection(f)}
                  </Grid>
                );
              })}
            </Grid>
          </div>

          <div className={classes.filterGroup}>
            <Typography>
              {i18n.translate("FILTER_CONFIGURATION_OPTIONS_Scores")}
            </Typography>
            <Grid container>
              {filterGrouping.Scores.map((f) => {
                return (
                  <Grid item xs={12} md={6} key={f}>
                    {buildFilterSelection(f)}
                  </Grid>
                );
              })}
            </Grid>
          </div>

          <div className={classes.filterGroup}>
            <Typography>
              {i18n.translate("FILTER_CONFIGURATION_OPTIONS_Visit")}
            </Typography>
            <Grid container>
              {filterGrouping.Visit.map((f) => {
                const selectable = filterIsSelectable(f);
                return selectable ? (
                  <Grid item xs={12} md={6} key={f}>
                    {buildFilterSelection(f)}
                  </Grid>
                ) : null;
              })}
            </Grid>
          </div>

          <div className={classes.filterGroup}>
            <Typography>
              {i18n.translate("LOCATION_PICKER_Locations")}:
            </Typography>
            <Grid container>
              {filterGrouping.Locations.map((f) => {
                return (
                  <Grid item xs={12} md={6} key={f}>
                    {buildFilterSelection(f)}
                  </Grid>
                );
              })}
            </Grid>
          </div>

          <div className={classes.filterGroup}>
            <Typography>
              {i18n.translate("FILTER_CONFIGURATION_OPTIONS_InboxTasks")}
            </Typography>
            <Grid container>
              {filterGrouping.InboxTasks.map((f) => {
                return (
                  <Grid item xs={12} md={6} key={f}>
                    {buildFilterSelection(f)}
                  </Grid>
                );
              })}
            </Grid>
          </div>

          <div className={classes.filterGroup}>
            <Typography>
              {i18n.translate("FILTER_CONFIGURATION_OPTIONS_Time")}
            </Typography>
            <Grid container>
              {filterGrouping.Time.map((f) => {
                return (
                  <Grid item xs={12} md={6} key={f}>
                    {buildFilterSelection(f)}
                  </Grid>
                );
              })}
            </Grid>
          </div>

          {showOtherFiltersSection && (
            <div className={classes.filterGroup}>
              <Typography>
                {i18n.translate("FILTER_CONFIGURATION_OPTIONS_Other")}
              </Typography>
              <Grid container>
                {filterGrouping.Other.map((f) => {
                  const selectable = filterIsSelectable(f);
                  return selectable ? (
                    <Grid item xs={12} md={6} key={f}>
                      {buildFilterSelection(f)}
                    </Grid>
                  ) : null;
                })}
              </Grid>
            </div>
          )}

          <div className={classes.dialogDivider}></div>

          <Button
            variant="contained"
            onClick={handleApply}
            className={classes.button}
            color="primary"
          >
            {i18n.translate("FILTER_CONFIGURATION_OPTIONS_Apply")}
          </Button>

          <Button
            onClick={() => setSelectedFilters([])}
            className={classes.button}
          >
            {i18n.translate("FILTER_CONFIGURATION_OPTIONS_Reset")}
          </Button>

          <Button onClick={handleCancel} className={classes.button}>
            {i18n.translate("FILTER_CONFIGURATION_OPTIONS_Cancel")}
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FilterConfigurationOptions;
