import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import { Button } from "@material-ui/core";
import TreeView from "@material-ui/lab/TreeView";
import { GenericTreeViewStyles } from "./styles/genericTreeViewStyles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import SimpleBar from "simplebar-react";
import SearchTextInput from "../searchTextInput/searchTextInput";
import GenericTreeViewNodes from "./components/genericTreeViewNodes";
import { i18n } from "../../../localizations";

interface IProps {
  nodeOptions: any[];
  nodeSequence: string[];
  selectedNodes: string[];
  applySelectionCallback: (selections: string[]) => void;
  cancelSelectionCallback: () => void;
  nodeSelectedCallback?: (node: string, nodeType: string) => void;
  filterPlaceholderText?: string | undefined;
  expandAllByDefault?: boolean;
  leafNodeTooltipProperty?: string;
  sortNodes: boolean;
  disabledNodes?: any[];
  clearSelectedNodesCallback?: () => void;
  selectAllNodesCallback?: () => void;
  selectedDisabledNodeCallback?: Dispatch<SetStateAction<boolean>>;
}

const GenericTreeView = (props: IProps) => {
  const classes = GenericTreeViewStyles();

  const getAllNodesForDefaultExpansion = (): string[] => {
    const allOptions: string[] = [];
    props.nodeOptions.forEach((option: any) => {
      props.nodeSequence.forEach((nodeType, index) => {
        allOptions.push(`node:${index}:${option[nodeType]}`);
      });
    });

    return allOptions;
  };

  const [nodeOptions, setNodeOptions] = useState(props.nodeOptions);
  const [selectedNodes, setSelectedNodes] = useState([] as string[]);

  const [expandedNodes, setExpandedNodes] = useState(
    props.expandAllByDefault
      ? getAllNodesForDefaultExpansion()
      : new Array<string>()
  );

  const handleNodeToggle = (event: any, nodeIds: string[]) => {
    if (
      event.target?.type !== "checkbox" &&
      event.target?.tagName !== "SPAN" &&
      event.target?.tagName !== "LABEL"
    ) {
      setExpandedNodes(nodeIds);
    }
  };

  const handleNodeSelection = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement>,
      nodeType: string,
      disabled = false
    ) => {
      const node = event.target.name;
      const leafNodeType = props.nodeSequence[props.nodeSequence.length - 1];
      if (disabled) {
        props.selectedDisabledNodeCallback?.(true);
        return;
      }

      const releatedNodes = nodeOptions.filter((x: any) => {
        return x[nodeType] === node;
      });

      let mergeStates = selectedNodes;

      if (event.target.checked) {
        releatedNodes.forEach((n: any) => {
          mergeStates = mergeStates.filter((f) => {
            return f !== n[leafNodeType];
          });
          mergeStates.push(n[leafNodeType]);
        });
      } else {
        releatedNodes.forEach((n: any) => {
          mergeStates = mergeStates.filter((f) => {
            return f !== n[leafNodeType];
          });
        });
      }

      setSelectedNodes(mergeStates);

      if (props.nodeSelectedCallback) {
        props.nodeSelectedCallback(node, leafNodeType);
      }
    },
    [nodeOptions, props, selectedNodes]
  );

  const handleLeafNodeSelection = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, disabled = false) => {
      if (disabled === true) {
        props.selectedDisabledNodeCallback?.(true);
        return;
      }
      const locations = selectedNodes.filter((n) => {
        return n !== event.target.name;
      });

      if (event.target.checked) {
        locations.push(event.target.name);
      }

      setSelectedNodes(locations);

      if (props.nodeSelectedCallback) {
        props.nodeSelectedCallback(
          event.target.name,
          props.nodeSequence[props.nodeSequence.length - 1]
        );
      }
    },
    [selectedNodes, props]
  );

  const handleClearSelections = () => {
    setSelectedNodes([]);
    props.clearSelectedNodesCallback?.();
  };

  const handleSelectAll = () => {
    const leafNodeType = props.nodeSequence[props.nodeSequence.length - 1];
    const allOptions = nodeOptions.map((f: any) => f[leafNodeType]);

    setSelectedNodes(allOptions);
    props.selectAllNodesCallback?.();
  };

  const handleExpandAll = () => {
    expandAllNodes();
  };

  const handleCollapseAll = () => {
    setExpandedNodes([]);
  };

  const expandAllNodes = () => {
    const allNodes: string[] = [];
    props.nodeOptions.forEach((option: any) => {
      props.nodeSequence.forEach((nodeType, index) => {
        allNodes.push(`node:${index}:${option[nodeType]}`);
      });
    });

    setTimeout(() => {
      setExpandedNodes(allNodes);
    }, 100);
  };

  const handleCancel = () => {
    closeAndReset();
  };

  const handleApply = () => {
    props.applySelectionCallback(selectedNodes);
    closeAndReset();
  };

  const closeAndReset = () => {
    props.cancelSelectionCallback();
    setSelectedNodes(props.selectedNodes);
  };

  const getSelectionText = (): string => {
    const nodes = selectedNodes.length;
    return nodes === 0
      ? i18n.translate("GENERIC_TREE_VIEW_All_selected")
      : `${nodes} ${i18n.translate("GENERIC_TREE_VIEW_selected")}`;
  };

  const clearSearch = () => {
    setNodeOptions(props.nodeOptions);
  };

  const applySearch = (searchValue: string) => {
    if (searchValue.trim().length > 0) {
      const searchValueLower = searchValue.toLowerCase();
      const filteredLocations = props.nodeOptions.filter((o) => {
        let nodeContainsMatch = false;
        props.nodeSequence.forEach((node) => {
          if (o[node] && o[node].toLowerCase().includes(searchValueLower)) {
            nodeContainsMatch = true;
          }
        });

        return nodeContainsMatch;
      });
      setNodeOptions(filteredLocations);
      if (filteredLocations.length < 50) {
        expandAllNodes();
      }
    } else {
      setNodeOptions(props.nodeOptions);
    }
  };

  const showExpandControls = (): boolean => {
    return props.nodeSequence.length > 1;
  };

  const renderNodes = useMemo(() => {
    return (
      <GenericTreeViewNodes
        nodeOptions={nodeOptions}
        nodeSequence={props.nodeSequence}
        selectedNodes={selectedNodes}
        onNodeSelected={handleNodeSelection}
        onLeafNodeSelected={handleLeafNodeSelection}
        leafNodeTooltipProperty={props.leafNodeTooltipProperty}
        sortNodes={props.sortNodes}
        disabledNodes={props.disabledNodes}
      />
    );
  }, [
    handleLeafNodeSelection,
    handleNodeSelection,
    nodeOptions,
    props,
    selectedNodes,
  ]);

  const itemsAreFiltered = (): boolean => {
    return nodeOptions.length < props.nodeOptions.length;
  };

  useEffect(() => {
    setSelectedNodes(props.selectedNodes);
  }, [props.selectedNodes, setSelectedNodes]);

  return (
    <div className={classes.container}>
      <SearchTextInput
        label=""
        searchPlaceholder={props.filterPlaceholderText}
        onClearSearch={clearSearch}
        onSearch={applySearch}
      />
      {itemsAreFiltered() && (
        <div className={classes.filterDescription}>
          {i18n.translate("GENERIC_TREE_VIEW_Showing")} {nodeOptions.length}{" "}
          {i18n.translate("GENERIC_TREE_VIEW_Of")} {props.nodeOptions.length}{" "}
          {i18n.translate("GENERIC_TREE_VIEW_Items")}
        </div>
      )}

      <SimpleBar className={classes.treeContainer}>
        <TreeView
          className={classes.treeView}
          defaultCollapseIcon={
            <ExpandMoreIcon className={classes.expandIcons} />
          }
          defaultExpandIcon={<ChevronRightIcon />}
          disableSelection={true}
          expanded={expandedNodes}
          onNodeToggle={handleNodeToggle}
        >
          {renderNodes}
        </TreeView>
      </SimpleBar>

      <div className={classes.subTasks}>
        {showExpandControls() && (
          <>
            <Button size="small" onClick={handleExpandAll}>
              {i18n.translate("GENERIC_TREE_VIEW_ExpandAll")}
            </Button>
            <Button size="small" onClick={handleCollapseAll}>
              {i18n.translate("GENERIC_TREE_VIEW_Collapse_All")}
            </Button>
          </>
        )}

        <Button size="small" onClick={handleSelectAll}>
          {i18n.translate("GENERIC_TREE_VIEW_Select_All")}
        </Button>
        <Button size="small" onClick={handleClearSelections}>
          {i18n.translate("GENERIC_TREE_VIEW_Clear_Selections")}
        </Button>
      </div>

      <div className={classes.actions}>
        <div className={classes.status}>{getSelectionText()}</div>
        <Button onClick={handleApply} variant="contained" color="primary">
          {i18n.translate("GENERIC_TREE_VIEW_Apply")}
        </Button>
        <Button onClick={handleCancel}>
          {i18n.translate("GENERIC_TREE_VIEW_Cancel")}
        </Button>
      </div>
    </div>
  );
};

export default GenericTreeView;
