import dayJs from "dayjs";
import * as pbi from "powerbi-client";
import * as models from "powerbi-models";
import {
  IVisitDateFilter,
  HubFilterType,
} from "../../../state/types/FilterSets";
import { i18n } from "../../../localizations";
import dayjs from "dayjs";

const PowerBI_FilterSchema_Basic = "http://powerbi.com/product/schema#basic";
const PowerBI_FilterSchema_Advanced =
  "http://powerbi.com/product/schema#advanced";
const PowerBI_SlicerSchema_Target =
  "http://powerbi.com/product/schema#slicerTargetSelector";

export enum defaultFilterResponse {
  returnUndefined = 0,
  returnFilterForAll = 1,
}

export const buildBasicFilterObject = (
  values: Array<string | number | Date> | undefined,
  targetType: string,
  emptyValueResponse: defaultFilterResponse = defaultFilterResponse.returnUndefined
): pbi.models.IBasicFilter | undefined => {
  let filter = undefined;
  if (values && values.length > 0) {
    const isTaskCenterWithOther =
      targetType === HubFilterType.TaskCentreCategory &&
      values.includes("Other");

    filter = {
      $schema: PowerBI_FilterSchema_Basic,
      target: {
        table: i18n.translate(`POWERBI_FILTER_${targetType}_TABLE`),
        column: i18n.translate(`POWERBI_FILTER_${targetType}_COLUMN`),
      },
      operator: "In",
      values: isTaskCenterWithOther
        ? [...values.filter((v) => v !== "Other"), null]
        : values,
      filterType: pbi.models.FilterType.Basic,
      displaySettings: {
        displayName: "HUB",
        isHiddenInViewMode: true,
      },
    } as pbi.models.IBasicFilter;
  }

  if (
    filter === undefined &&
    emptyValueResponse === defaultFilterResponse.returnFilterForAll
  ) {
    filter = {
      $schema: PowerBI_FilterSchema_Basic,
      target: {
        table: i18n.translate(`POWERBI_FILTER_${targetType}_TABLE`),
        column: i18n.translate(`POWERBI_FILTER_${targetType}_COLUMN`),
      },
      operator: "All",
      values: [],
      filterType: pbi.models.FilterType.Basic,
      displaySettings: {
        displayName: "HUB",
        isHiddenInViewMode: true,
      },
    } as pbi.models.IBasicFilter;
  }

  return filter;
};

export const buildDateRangeFilterObject = (
  startDate: Date | undefined,
  endDate: Date | undefined,
  targetType: string
): pbi.models.IBasicFilter | pbi.models.IAdvancedFilter => {
  if (startDate && endDate) {
    return {
      $schema: PowerBI_FilterSchema_Advanced,
      target: {
        table: i18n.translate(`POWERBI_FILTER_${targetType}_TABLE`),
        column: i18n.translate(`POWERBI_FILTER_${targetType}_COLUMN`),
      },
      logicalOperator: "And",
      conditions: [
        {
          operator: "GreaterThanOrEqual",
          value: dayJs(startDate).format(
            i18n.translate("POWERBI_FILTER_VISIT_DATE_FORMAT_START")
          ),
        },
        {
          operator: "LessThanOrEqual",
          value: dayJs(endDate).format(
            i18n.translate("POWERBI_FILTER_VISIT_DATE_FORMAT_END")
          ),
        },
      ],
      filterType: pbi.models.FilterType.Advanced,
      displaySettings: {
        displayName: "HUB",
        isHiddenInViewMode: true,
      },
    } as pbi.models.IAdvancedFilter;
  } else {
    return {
      $schema: PowerBI_FilterSchema_Basic,
      target: {
        table: i18n.translate(`POWERBI_FILTER_${targetType}_TABLE`),
        column: i18n.translate(`POWERBI_FILTER_${targetType}_COLUMN`),
      },
      operator: "All",
      values: [],
      filterType: pbi.models.FilterType.Basic,
      displaySettings: {
        displayName: "HUB",
        isHiddenInViewMode: true,
      },
    } as pbi.models.IBasicFilter;
  }
};

export const buildNumericRangeFilter = (
  minValue: number,
  maxValue: number,
  targetType: string
): pbi.models.IAdvancedFilter | undefined => {
  if (minValue === 0 && maxValue === 100) {
    return undefined;
  }

  const filter: pbi.models.IAdvancedFilter = {
    $schema: PowerBI_FilterSchema_Advanced,
    target: {
      table: i18n.translate(`POWERBI_FILTER_${targetType}_TABLE`),
      column: i18n.translate(`POWERBI_FILTER_${targetType}_COLUMN`),
    },
    logicalOperator: "And",
    conditions: new Array<pbi.models.IAdvancedFilterCondition>(),
    filterType: pbi.models.FilterType.Advanced,
    displaySettings: {
      displayName: "HUB",
      isHiddenInViewMode: true,
    },
  };

  if (minValue > 0 && filter.conditions) {
    filter.conditions.push({ operator: "GreaterThanOrEqual", value: minValue });
  }

  if (maxValue < 100 && filter.conditions) {
    filter.conditions.push({ operator: "LessThanOrEqual", value: maxValue });
  }

  if (!filter.conditions || filter.conditions.length === 0) {
    return undefined;
  } else {
    return filter;
  }
};

export const buildHubDateFilter = (
  dateFilter: IVisitDateFilter
): pbi.models.IBasicFilter | pbi.models.IAdvancedFilter | undefined => {
  let filter: pbi.models.IBasicFilter | pbi.models.IAdvancedFilter | undefined =
    undefined;
  const isRawData = window.location.pathname.includes("raw-data");
  const MAXIMUM_SELECTABLE_DAYS = parseInt(
    process.env.REACT_APP_RAW_DATA_MAXIMUM_DAYS ?? "0"
  );
  const limitDates = isRawData && MAXIMUM_SELECTABLE_DAYS > 0;

  if (dateFilter.customDateRange !== null) {
    let dateStart = dateFilter.customDateRange.dateStart;

    if (limitDates) {
      if (
        dayjs(dateFilter.customDateRange.dateEnd).diff(
          dayjs(dateStart),
          "day"
        ) > MAXIMUM_SELECTABLE_DAYS
      ) {
        dateStart = dayjs(dateFilter.customDateRange.dateEnd)
          .subtract(MAXIMUM_SELECTABLE_DAYS, "day")
          .toDate();
      }
    }
    filter = buildDateRangeFilterObject(
      dateStart,
      dateFilter.customDateRange?.dateEnd,
      "VisitDate"
    );
  } else if (dateFilter.selectedPeriods.length > 0) {
    filter = buildBasicFilterObject(
      dateFilter.selectedPeriods,
      "Period_Name",
      defaultFilterResponse.returnFilterForAll
    );
  } else if (dateFilter.selectedPeriodHierarchies.length > 0) {
    filter = buildBasicFilterObject(
      dateFilter.selectedPeriodHierarchies,
      "PeriodHierarchy_Id",
      defaultFilterResponse.returnFilterForAll
    );
  }

  return filter;
};

export const getVisitDateSlicerConfig = (
  dateFilter: IVisitDateFilter
): models.ISlicer => {
  const targetType = "VisitDate";
  const dateRangeFilterValue = buildDateRangeFilterObject(
    dateFilter.customDateRange?.dateStart,
    dateFilter.customDateRange?.dateEnd,
    targetType
  );

  return {
    selector: {
      $schema: PowerBI_SlicerSchema_Target,
      target: {
        table: i18n.translate(`POWERBI_FILTER_${targetType}_TABLE`),
        column: i18n.translate(`POWERBI_FILTER_${targetType}_COLUMN`),
      },
    },
    state: {
      filters: [dateRangeFilterValue],
    },
  } as pbi.models.ISlicer;
};

export const getVisitPeriodSlicerConfig = (
  dateFilter: IVisitDateFilter
): models.ISlicer => {
  const targetType = "Period_Name";
  const periodFilterValue = buildBasicFilterObject(
    dateFilter.selectedPeriods,
    targetType,
    defaultFilterResponse.returnFilterForAll
  );

  return {
    selector: {
      $schema: PowerBI_SlicerSchema_Target,
      target: {
        table: i18n.translate(`POWERBI_FILTER_${targetType}_TABLE`),
        column: i18n.translate(`POWERBI_FILTER_${targetType}_COLUMN`),
      },
    },
    state: {
      filters: [periodFilterValue],
    },
  } as pbi.models.ISlicer;
};

export const getVisitPeriodHierarchySlicerConfig = (
  dateFilter: IVisitDateFilter
): models.ISlicer => {
  const targetType = "PeriodHierarchy_Id";
  const periodFilterValue = buildBasicFilterObject(
    dateFilter.selectedPeriodHierarchies,
    targetType,
    defaultFilterResponse.returnFilterForAll
  );

  return {
    selector: {
      $schema: PowerBI_SlicerSchema_Target,
      target: {
        table: i18n.translate(`POWERBI_FILTER_${targetType}_TABLE`),
        column: i18n.translate(`POWERBI_FILTER_${targetType}_COLUMN`),
      },
    },
    state: {
      filters: [periodFilterValue],
    },
  } as pbi.models.ISlicer;
};

export const getLocationRankSlicerConfig = (
  locationId: number
): models.ISlicer => {
  const targetType = HubFilterType[HubFilterType.LocationRank];
  const rankFilter = buildBasicFilterObject([locationId], targetType);

  return {
    selector: {
      $schema: PowerBI_SlicerSchema_Target,
      target: {
        table: i18n.translate(`POWERBI_FILTER_${targetType}_TABLE`),
        column: i18n.translate(`POWERBI_FILTER_${targetType}_COLUMN`),
      },
    },
    state: {
      filters: [rankFilter],
    },
  } as pbi.models.ISlicer;
};

export const extractNonHubFilters = (
  filters: pbi.models.IFilter[]
): pbi.models.IFilter[] => {
  return filters.filter((f) => {
    return (
      f.displaySettings === undefined ||
      f.displaySettings.displayName !== "HUB" ||
      f.displaySettings.isHiddenInViewMode === false
    );
  });
};
