import { useDispatch, useSelector } from "react-redux";
import {
  HubFilterType,
  IHubFilter,
  ILocationFilter,
  IVisitDateFilter,
  VisitDateSelectionType,
  IVIsitDateRange,
  IAreaAndRegionFilter,
  ILocationSegmentFilter,
  ILocationRankFilter,
  IVisitTypeFilter,
  IQuestionnaireTypeFilter,
  IQuestionsFilter,
  IQuestionSectionFilter,
  IQuestionCategoryFilter,
  IBenchmarksFilter,
  INetPromoterScoreFilter,
  IVisitScoreRangeFilter,
  IQuestionScoreRangeFilter,
  IDayOfWeekFilter,
  IDayPartFilter,
  ITaskCentreStatusFilter,
  ITaskCentreCategoryFilter,
  ITaskCentrePriorityFilter,
  IKeyValuePair,
  IVisitIdFilter,
  IVisitSourceFilter,
  IHubTextFilter,
  ITaskAssigneeFilter,
  IVisitContactStatusFilter,
  IAppealStatusFilter,
} from "../../../state/types/FilterSets";
import DisabledFilter from "../../_common/filters/disabledFilter";
import LocationPicker from "../../_common/filters/locationPicker";
import DateAndPeriodPicker from "../../_common/filters/dateAndPeriodPicker";
import AreaRegionPicker from "../../_common/filters/areaRegionPicker";
import SegmentPicker from "../../_common/filters/segmentsPicker";
import LocationRankPicker from "../../_common/filters/locationRankFilter";
import VisitTypePicker from "../../_common/filters/visitTypePicker";
import { updateCurrentFiltersEntry } from "../../../state/actions/FilterSets-Actions";
import QuestionnaireTypePicker from "../../_common/filters/questionnaireTypePicker";
import QuestionPicker from "../../_common/filters/questionPicker";
import QuestionSectionPicker from "../../_common/filters/questionSectionPicker";
import QuestionCategoryPicker from "../../_common/filters/questionCategoryPicker";
import BenchmarksPicker from "../../_common/filters/benchmarksPicker";
import NetPromoterScorePicker from "../../_common/filters/npsPicker";
import QuestionScorePicker from "../../_common/filters/questionScorePicker";
import VisitScorePicker from "../../_common/filters/visitScorePicker";
import DayPicker from "../../_common/filters/dayPicker";
import DayPartPicker from "../../_common/filters/dayPartPicker";
import TaskCentreStatusPicker from "../../_common/filters/taskCentreStatusPicker";
import AppealStatusPicker from "../../_common/filters/appealStatusPicker";
import TaskCentreCategoryPicker from "../../_common/filters/taskCentreCategoryPicker";
import TaskCentrePriorityPicker from "../../_common/filters/taskCentrePriorityPicker";
import VisitIdPicker from "../../_common/filters/visitIdPicker";
import VisitSourcePicker from "../../_common/filters/visitSourcePicker";
import AnalysisTagsValuePicker from "../../_common/filters/analysisTagValuePicker";
import TaskAssigneePicker from "../../_common/filters/taskAssigneePicker";
import VisitContactStatusPicker from "../../_common/filters/visitContactStatusPicker";
import { CaseInsensitiveKeyValueSort } from "../../../utils/sortnig";
import {
  getQuestionnairesTypesForSelectedVisitTypes,
  filterUnavailableSelections,
  getQuestionsForSelectedSections,
  getQuestionBenchmarksForSelectedQuestionnaireTypes,
  getQuestionCategoriesForSelectedQuestionnaireTypes,
  getGroupedQuestionSectionsForSelectedQuestionnaireTypes,
  getTagValuesForSelectedQuestionnaireTypes,
  filterUnavailableKeyValueSelections,
} from "../../_common/filters/utils/filterOptionSelector";
import {
  DishFilterOptions,
  ILocationFilterOption,
  IPeriodHierarchyOption,
  IQuestionnaireFilterOption,
  IVisitDateFilterOptions,
} from "../../../state/types/FilterOptions";
import { ICaseCategory, IManager } from "../../../state/types/TaskCentreCases";
import { useHistory } from "react-router-dom";
import { CurrentUserId } from "../../taskCentreEvents/state/selectors";
import { i18n } from "../../../localizations";
import BrandPicker from "../../_common/filters/brandPicker";
import { useAllowedVisitTypes } from "../../../utils/useAllowedVisitTypes";
import { HGEMVisitType } from "../../../utils/hgem";
import { useClientId } from "../../../state/hooks/clients/useClientId";

interface IProps {
  hubFilter: IHubFilter;
  reportIsLoading: boolean;
  pageInternalRef: string;
  allCurrentFilters: IHubFilter[];
  locationOptions: ILocationFilterOption[];
  visitDateOptions: IVisitDateFilterOptions;
  periodHierarchyOptions: IPeriodHierarchyOption[];
  questionnaireOptions: IQuestionnaireFilterOption[];
  taskCentreCategoryOptions: ICaseCategory[];
  taskAssigneeOptions: IManager[];
  appealsFeatureActive?: boolean;
  dishFilterOptions?: DishFilterOptions[];
}

const ReportFilter = (props: IProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { allowedVisitTypes } = useAllowedVisitTypes();
  const clientId = useClientId();

  const LocationSegmentFilter = () => {
    const segments = [
      ...new Set(
        props.locationOptions.filter((x) => !!x.segment).map((x) => x.segment)
      ),
    ];

    const nonEmptySegments: string[] = [];
    segments.forEach((x) => {
      if (x) {
        nonEmptySegments.push(x);
      }
    });

    const segmentFilter = props.hubFilter as ILocationSegmentFilter;

    const selected = (selectedSegments: string[]) => {
      const selectedLocations = props.locationOptions.filter((location) => {
        if (location.segment && selectedSegments.includes(location.segment)) {
          return location;
        }
      });

      const filteredLocations = selectedLocations.map((location) => {
        return { key: location.branchId, value: location.branchName };
      });
      if (filteredLocations) {
        dispatch(
          updateCurrentFiltersEntry(
            HubFilterType.Locations,
            (x: ILocationFilter) => {
              x.value = filteredLocations;
            }
          )
        );
      }
    };

    return (
      <SegmentPicker
        key="areaRegionPicker"
        availableSegments={nonEmptySegments}
        selectedSegments={segmentFilter.value}
        applySelectionCallback={selected}
      />
    );
  };
  const LocationFilter = () => {
    const locationFilter = props.hubFilter as ILocationFilter;

    const selected = (selectedLocations: IKeyValuePair[]) => {
      dispatch(
        updateCurrentFiltersEntry(
          HubFilterType.Locations,
          (x: ILocationFilter) => {
            x.value = selectedLocations;
          }
        )
      );
    };

    if (locationFilter.value.length > 0) {
      const filteredValues = locationFilter.value.filter((x) =>
        props.locationOptions.find((o) => o.branchId === x.key)
      );

      if (locationFilter.value.length !== filteredValues.length) {
        selected(filteredValues);
      }
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "101.5%",
          margin: "0 1% 0 -1%",
        }}
      >
        <LocationPicker
          key="locationPicker"
          availableLocations={props.locationOptions}
          selectedLocations={locationFilter.value}
          applySelectionCallback={selected}
        />
      </div>
    );
  };

  const VisitDateFilter = () => {
    const dateFilter = props.hubFilter as IVisitDateFilter;

    const periodsSelected = (
      periods: string[],
      rangeType: VisitDateSelectionType
    ) => {
      dispatch(
        updateCurrentFiltersEntry(
          HubFilterType.VisitDate,
          (x: IVisitDateFilter) => {
            x.dateSelectionType = rangeType;
            x.selectedPeriods = periods;
            x.selectedPeriodHierarchies = [];
            x.customDateRange = null;
            x.expandedPeriodHierarchyNodes = [];
          }
        )
      );
    };

    const periodHierarchySelected = (
      periods: number[],
      rangeType: VisitDateSelectionType,
      expandedNodes: string[]
    ) => {
      dispatch(
        updateCurrentFiltersEntry(
          HubFilterType.VisitDate,
          (x: IVisitDateFilter) => {
            x.dateSelectionType = rangeType;
            x.selectedPeriodHierarchies = periods;
            x.selectedPeriods = [];
            x.customDateRange = null;
            x.expandedPeriodHierarchyNodes = expandedNodes;
          }
        )
      );
    };

    const rangeSelected = (
      dateRange: IVIsitDateRange,
      rangeType: VisitDateSelectionType
    ) => {
      dispatch(
        updateCurrentFiltersEntry(
          HubFilterType.VisitDate,
          (x: IVisitDateFilter) => {
            x.dateSelectionType = rangeType;
            x.selectedPeriods = [];
            x.selectedPeriodHierarchies = [];
            x.expandedPeriodHierarchyNodes = [];
            x.customDateRange = {
              dateStart: dateRange.dateStart,
              dateEnd: dateRange.dateEnd,
            } as IVIsitDateRange;
          }
        )
      );
    };

    return (
      <DateAndPeriodPicker
        key="datePicker"
        availableLegacyPeriods={props.visitDateOptions.clientPeriods}
        periodHierarchyOptions={props.periodHierarchyOptions}
        dateSelectionType={dateFilter.dateSelectionType}
        selectedLegacyPeriods={dateFilter.selectedPeriods}
        selectedPeriodHierarchies={dateFilter.selectedPeriodHierarchies}
        expandedPeriodHierarchyNodes={dateFilter.expandedPeriodHierarchyNodes}
        selectedCustomRange={dateFilter.customDateRange}
        applyLegacyPeriodSelectionCallback={periodsSelected}
        applyPeriodHeiorarchySelectionCallback={periodHierarchySelected}
        applySelectionRangeCallback={rangeSelected}
      />
    );
  };

  const AreaAndRegionFilter = () => {
    const areaRegionFilter = props.hubFilter as IAreaAndRegionFilter;

    const selected = (selectedAreas: string[]) => {
      dispatch(
        updateCurrentFiltersEntry(
          HubFilterType.AreasAndRegions,
          (x: IAreaAndRegionFilter) => {
            x.value = selectedAreas;
          }
        )
      );
    };

    return (
      <AreaRegionPicker
        key="segmentPicker"
        availableLocations={props.locationOptions}
        selectedAreas={areaRegionFilter.value}
        applySelectionCallback={selected}
      />
    );
  };

  const LocationRankFilter = () => {
    const locations = [
      ...new Set(
        props.locationOptions
          .map((x) => {
            return {
              key: x.branchId,
              value: x.branchName,
            } as IKeyValuePair;
          })
          .sort(CaseInsensitiveKeyValueSort)
      ),
    ];

    const locationRankFilter = props.hubFilter as ILocationRankFilter;

    if (
      (locationRankFilter.selectedLocation === undefined ||
        locationRankFilter.selectedLocation.key <= 0) &&
      locations.length > 0
    ) {
      dispatch(
        updateCurrentFiltersEntry(
          HubFilterType.LocationRank,
          (x: ILocationRankFilter) => {
            x.selectedLocation = locations[0];
          }
        )
      );
    }

    const selected = (selectedLocation: IKeyValuePair) => {
      dispatch(
        updateCurrentFiltersEntry(
          HubFilterType.LocationRank,
          (x: ILocationRankFilter) => {
            x.selectedLocation = selectedLocation;
          }
        )
      );
    };

    return (
      <LocationRankPicker
        key="locationRankPicker"
        availableLocations={locations}
        selectedLocation={locationRankFilter.selectedLocation}
        applySelectionCallback={selected}
      />
    );
  };

  const VisitTypeFilter = () => {
    const visitTypes = [
      ...new Set(
        props.questionnaireOptions
          .map((x) => x.visitType)
          .filter((visitType) =>
            allowedVisitTypes.includes(visitType as HGEMVisitType)
          )
      ),
    ];
    if (visitTypes.length === 0) {
      visitTypes.push("INVALID_VISIT_TYPE");
    }

    const selected = (selectedVisitTypes: string[]) => {
      dispatch(
        updateCurrentFiltersEntry(
          HubFilterType.VisitType,
          (x: IVisitTypeFilter) => {
            x.value = selectedVisitTypes;
          }
        )
      );
    };

    const visitTypeFilter = props.hubFilter as IVisitTypeFilter;
    const selectedValues = filterUnavailableSelections(
      visitTypes,
      visitTypeFilter.value
    );

    if (visitTypeFilter.value.length !== selectedValues.length) {
      selected(selectedValues);
    }

    return (
      <VisitTypePicker
        availableVisitTypes={visitTypes}
        selectedVisitTypes={selectedValues}
        applySelectionCallback={selected}
      />
    );
  };

  const VisitSourceFilter = () => {
    const visitSources = props.hubFilter as IVisitSourceFilter;

    const selected = (selectedVisitSources: IKeyValuePair[]) => {
      if (selectedVisitSources) {
        dispatch(
          updateCurrentFiltersEntry(
            HubFilterType.VisitSource,
            (x: IVisitSourceFilter) => {
              x.value = selectedVisitSources;
            }
          )
        );
      }
    };

    const visitSourceFilter = props.hubFilter as IVisitSourceFilter;
    const selectedValues = filterUnavailableKeyValueSelections(
      visitSources.value,
      visitSourceFilter.value
    );
    if (visitSourceFilter.value.length !== selectedValues.length) {
      selected(selectedValues);
    }

    return (
      <VisitSourcePicker
        clientId={clientId}
        selectedVisitSources={selectedValues}
        applySelectionCallback={selected}
      />
    );
  };

  const QuestionnaireTypeFilter = () => {
    const sourceQuestionnaireTypes =
      getQuestionnairesTypesForSelectedVisitTypes(
        props.questionnaireOptions,
        props.allCurrentFilters
      );
    const questionnaireTypes = [...new Set(sourceQuestionnaireTypes)];

    const selected = (selectedQuestionnaireTypes: string[]) => {
      dispatch(
        updateCurrentFiltersEntry(
          HubFilterType.QuestionnaireType,
          (x: IQuestionnaireTypeFilter) => {
            x.value = selectedQuestionnaireTypes;
          }
        )
      );
    };

    const questionnaireTypeFilter = props.hubFilter as IQuestionnaireTypeFilter;
    const selectedValues = filterUnavailableSelections(
      questionnaireTypes,
      questionnaireTypeFilter.value
    );

    if (questionnaireTypeFilter.value.length !== selectedValues.length) {
      selected(selectedValues);
    }

    return (
      <QuestionnaireTypePicker
        availableQuestionnaireTypes={questionnaireTypes}
        selectedQuestionnaireypes={selectedValues}
        applySelectionCallback={selected}
      />
    );
  };

  const QuestionsFilter = () => {
    const sourceQuestions = getQuestionsForSelectedSections(
      props.questionnaireOptions,
      props.allCurrentFilters
    );

    const questions = [...new Set(sourceQuestions.Questions)];
    const questionSubjects = [...new Set(sourceQuestions.QuestionSubjects)];

    const selected = (questions: string[], questionSubjects: string[]) => {
      dispatch(
        updateCurrentFiltersEntry(
          HubFilterType.Questions,
          (x: IQuestionsFilter) => {
            x.selectedQuestions = questions;
            x.selectedQuestionSubjects = questionSubjects;
          }
        )
      );
    };

    const questionsFilter = props.hubFilter as IQuestionsFilter;
    const selectedQuestionValues = filterUnavailableSelections(
      questions,
      questionsFilter.selectedQuestions
    );
    const selectedQuestionSubjectValues = filterUnavailableSelections(
      questionSubjects,
      questionsFilter.selectedQuestionSubjects
    );

    if (
      questionsFilter.selectedQuestions.length !==
        selectedQuestionValues.length ||
      questionsFilter.selectedQuestionSubjects.length !==
        selectedQuestionSubjectValues.length
    ) {
      selected(selectedQuestionValues, selectedQuestionSubjectValues);
    }

    return (
      <QuestionPicker
        availableQuestions={questions}
        availableQuestionSubjects={questionSubjects}
        selectedQuestions={selectedQuestionValues}
        selectedQuestionSubjects={selectedQuestionSubjectValues}
        onApply={selected}
      />
    );
  };

  const QuestionSectionFilter = () => {
    const sectionGroups =
      getGroupedQuestionSectionsForSelectedQuestionnaireTypes(
        props.questionnaireOptions,
        props.allCurrentFilters
      );

    let sourceSections: string[] = [];
    sectionGroups.forEach((g) => {
      sourceSections = sourceSections.concat(g.values);
    });

    const selected = (selectedSections: string[]) => {
      dispatch(
        updateCurrentFiltersEntry(
          HubFilterType.Sections,
          (x: IQuestionSectionFilter) => {
            x.value = selectedSections;
          }
        )
      );
    };

    const sectionFilter = props.hubFilter as IQuestionSectionFilter;
    const selectedValues = filterUnavailableSelections(
      sourceSections,
      sectionFilter.value
    );

    if (sectionFilter.value.length !== selectedValues.length) {
      selected(selectedValues);
    }

    return (
      <QuestionSectionPicker
        availableSections={sourceSections}
        selectedSections={sectionFilter.value}
        applySelectionCallback={selected}
        sectionGroupings={sectionGroups}
      />
    );
  };

  const QuestionCategoryFilter = () => {
    const sourceCategories = getQuestionCategoriesForSelectedQuestionnaireTypes(
      props.questionnaireOptions,
      props.allCurrentFilters
    );

    const categories = [...new Set(sourceCategories)];

    const selected = (selectedCategories: string[]) => {
      dispatch(
        updateCurrentFiltersEntry(
          HubFilterType.QuestionCategory,
          (x: IQuestionCategoryFilter) => {
            x.value = selectedCategories;
          }
        )
      );
    };

    const categoryFilter = props.hubFilter as IQuestionCategoryFilter;
    const selectedValues = filterUnavailableSelections(
      categories,
      categoryFilter.value
    );

    if (categoryFilter.value.length !== selectedValues.length) {
      selected(selectedValues);
    }

    return (
      <QuestionCategoryPicker
        availableCategories={categories}
        selectedCategories={selectedValues}
        applySelectionCallback={selected}
      />
    );
  };

  const BenchmarksFilter = () => {
    const sourceBenchmarks = getQuestionBenchmarksForSelectedQuestionnaireTypes(
      props.questionnaireOptions,
      props.allCurrentFilters
    );

    const benchmarks = [...new Set(sourceBenchmarks)];

    const selected = (selectedBenchmarks: string[]) => {
      dispatch(
        updateCurrentFiltersEntry(
          HubFilterType.Benchmarks,
          (x: IBenchmarksFilter) => {
            x.value = selectedBenchmarks;
          }
        )
      );
    };

    const benchmarksFilter = props.hubFilter as IBenchmarksFilter;
    const selectedValues = filterUnavailableSelections(
      benchmarks,
      benchmarksFilter.value
    );

    if (benchmarksFilter.value.length !== selectedValues.length) {
      selected(selectedValues);
    }

    return (
      <BenchmarksPicker
        availableBenchmarks={benchmarks}
        selectedBenchmarks={selectedValues}
        applySelectionCallback={selected}
      />
    );
  };

  const NPSFilter = () => {
    const sourceValues: string[] = [
      i18n.translate("QUESTION_NPS_PICKER_VALUES_Promoter"),
      i18n.translate("QUESTION_NPS_PICKER_VALUES_Neutral"),
      i18n.translate("QUESTION_NPS_PICKER_VALUES_Detractor"),
    ];

    const selected = (selectedNPSValues: string[]) => {
      dispatch(
        updateCurrentFiltersEntry(
          HubFilterType.NPS,
          (x: INetPromoterScoreFilter) => {
            x.value = selectedNPSValues;
          }
        )
      );
    };

    const npsFilter = props.hubFilter as INetPromoterScoreFilter;
    return (
      <NetPromoterScorePicker
        availableNPSValues={sourceValues}
        selectedNPSValues={npsFilter.value}
        applySelectionCallback={selected}
      />
    );
  };

  const VisitScoreFilter = () => {
    const selected = (rangeMin: number, rangeMax: number) => {
      dispatch(
        updateCurrentFiltersEntry(
          HubFilterType.VisitScoreRange,
          (x: IVisitScoreRangeFilter) => {
            x.minValue = rangeMin;
            x.maxValue = rangeMax;
          }
        )
      );
    };

    const visitScoreFilter = props.hubFilter as IVisitScoreRangeFilter;
    return (
      <VisitScorePicker
        selectedMinValue={visitScoreFilter.minValue}
        selectedMaxValue={visitScoreFilter.maxValue}
        applySelectionCallback={selected}
      />
    );
  };

  const QuestionScoreFilter = () => {
    const selected = (rangeMin: number, rangeMax: number) => {
      dispatch(
        updateCurrentFiltersEntry(
          HubFilterType.QuestionScoreRange,
          (x: IQuestionScoreRangeFilter) => {
            x.minValue = rangeMin;
            x.maxValue = rangeMax;
          }
        )
      );
    };

    const questionScoreFilter = props.hubFilter as IQuestionScoreRangeFilter;
    return (
      <QuestionScorePicker
        availableMinValue={0}
        availableMaxValue={100}
        selectedMinValue={questionScoreFilter.minValue}
        selectedMaxValue={questionScoreFilter.maxValue}
        applySelectionCallback={selected}
      />
    );
  };

  const DayOfWeekFilter = () => {
    const selected = (selectedDays: string[]) => {
      if (selectedDays.length === 7) {
        selectedDays = [];
      }

      dispatch(
        updateCurrentFiltersEntry(
          HubFilterType.DayOfWeek,
          (x: IDayOfWeekFilter) => {
            x.value = selectedDays;
          }
        )
      );
    };

    const dayFilter = props.hubFilter as IDayOfWeekFilter;
    return (
      <DayPicker
        selectedDayValues={dayFilter.value}
        applySelectionCallback={selected}
      />
    );
  };

  const DayPartFilter = () => {
    const selected = (selectedDayParts: string[]) => {
      if (selectedDayParts.length === 5) {
        selectedDayParts = [];
      }

      dispatch(
        updateCurrentFiltersEntry(
          HubFilterType.DayPart,
          (x: IDayPartFilter) => {
            x.value = selectedDayParts;
          }
        )
      );
    };

    const dayPartFilter = props.hubFilter as IDayPartFilter;
    return (
      <DayPartPicker
        selectedDayPartValues={dayPartFilter.value}
        applySelectionCallback={selected}
      />
    );
  };

  const TaskCentreStatusFilter = () => {
    const selected = (selectedStatuses: IKeyValuePair[]) => {
      dispatch(
        updateCurrentFiltersEntry(
          HubFilterType.TaskCentreStatus,
          (x: ITaskCentreStatusFilter) => {
            x.value = selectedStatuses;
          }
        )
      );
    };

    const statusFilter = props.hubFilter as ITaskCentreStatusFilter;
    return (
      <TaskCentreStatusPicker
        selectedTaskStatuses={statusFilter.value}
        applySelectionCallback={selected}
      />
    );
  };

  const AppealStatusFilter = () => {
    const selected = (selectedStatuses: IKeyValuePair[]) => {
      dispatch(
        updateCurrentFiltersEntry(
          HubFilterType.AppealStatus,
          (x: IAppealStatusFilter) => {
            x.value = selectedStatuses;
          }
        )
      );
    };

    const statusFilter = props.hubFilter as IAppealStatusFilter;
    return (
      <AppealStatusPicker
        selectedTaskStatuses={statusFilter.value}
        applySelectionCallback={selected}
      />
    );
  };

  const TaskCentreCategoryFilter = () => {
    const selected = (selectedCategories: IKeyValuePair[]) => {
      dispatch(
        updateCurrentFiltersEntry(
          HubFilterType.TaskCentreCategory,
          (x: ITaskCentreCategoryFilter) => {
            x.value = selectedCategories;
          }
        )
      );
    };

    const categoryFilter = props.hubFilter as ITaskCentreCategoryFilter;
    return (
      <TaskCentreCategoryPicker
        availableCategories={props.taskCentreCategoryOptions}
        selectedTaskCategories={categoryFilter.value}
        applySelectionCallback={selected}
      />
    );
  };

  const TaskCentrePriorityFilter = () => {
    const selected = (selectedPriorities: IKeyValuePair[]) => {
      dispatch(
        updateCurrentFiltersEntry(
          HubFilterType.TaskCentrePriority,
          (x: ITaskCentrePriorityFilter) => {
            x.value = selectedPriorities;
          }
        )
      );
    };

    const priorityFilter = props.hubFilter as ITaskCentrePriorityFilter;
    return (
      <TaskCentrePriorityPicker
        selectedTaskPriorities={priorityFilter.value}
        applySelectionCallback={selected}
      />
    );
  };

  const VisitIdFilter = () => {
    const selected = (visitId: number | undefined) => {
      dispatch(
        updateCurrentFiltersEntry(
          HubFilterType.VisitId,
          (x: IVisitIdFilter) => {
            x.value = visitId;
          }
        )
      );

      const path = history.location.pathname;
      if (visitId) {
        history.replace(`${path}?visitId=${visitId}`);
      } else {
        history.replace(path);
      }
    };

    const visitIdFilter = props.hubFilter as IVisitIdFilter;

    return (
      <VisitIdPicker
        selectedVisitId={visitIdFilter.value}
        applySelectionCallback={selected}
      />
    );
  };

  const AnalysisTagsValueFilter = (
    filterType: HubFilterType,
    analysisTagName: string
  ) => {
    const sourceTagValues = getTagValuesForSelectedQuestionnaireTypes(
      props.questionnaireOptions,
      props.allCurrentFilters,
      analysisTagName
    );
    const tagValues = [...new Set(sourceTagValues)];

    const selected = (selectedValues: string[]) => {
      dispatch(
        updateCurrentFiltersEntry(filterType, (x: IHubTextFilter) => {
          x.value = selectedValues;
        })
      );
    };

    const tagValueFilter = props.hubFilter as IHubTextFilter;
    const selectedValues = filterUnavailableSelections(
      tagValues,
      tagValueFilter.value
    );

    if (tagValueFilter.value.length !== selectedValues.length) {
      selected(selectedValues);
    }

    return (
      <AnalysisTagsValuePicker
        availableValues={tagValues}
        selectedValues={selectedValues}
        tagName={analysisTagName}
        applySelectionCallback={selected}
      />
    );
  };

  const TaskAssigneeFilter = () => {
    const availableOptions = props.taskAssigneeOptions;

    const selected = (assignees: IManager[]) => {
      dispatch(
        updateCurrentFiltersEntry(
          HubFilterType.TaskAssignee,
          (x: ITaskAssigneeFilter) => {
            x.value = assignees.map((x) => {
              return {
                key: x.id,
                value: x.name,
              } as IKeyValuePair;
            });
          }
        )
      );
    };

    const taskAssigneeFilter = props.hubFilter as ITaskAssigneeFilter;

    return (
      <TaskAssigneePicker
        applySelectionCallback={selected}
        availableAssignees={availableOptions}
        selectedAssignees={taskAssigneeFilter.value.map((x) => x.key)}
      />
    );
  };

  const VisitContactStatusFilter = () => {
    const selected = (selectedValue: string) => {
      dispatch(
        updateCurrentFiltersEntry(
          HubFilterType.VisitContactStatus,
          (x: IVisitContactStatusFilter) => {
            x.value = [selectedValue];
          }
        )
      );
    };

    const visitStatusFilter = props.hubFilter as IVisitContactStatusFilter;
    const selectedValue =
      visitStatusFilter.value.length > 0 ? visitStatusFilter.value[0] : "";

    return (
      <VisitContactStatusPicker
        applySelectionCallback={selected}
        selectedValue={selectedValue}
      />
    );
  };

  const UnknownFilterType = () => {
    return <div data-filter-unknown={props.hubFilter.type}></div>;
  };

  switch (props.hubFilter.type) {
    // case HubFilterType.DishFilters:
    //   return props.reportIsLoading ? (
    //     <DisabledFilter label={"Filter disabled"} />
    //   ) : (
    //     DishesFilter()
    //   );
    case HubFilterType.Locations:
      return props.reportIsLoading ? (
        <DisabledFilter label={i18n.translate("LOCATION_PICKER_Locations")} />
      ) : (
        LocationFilter()
      );

    case HubFilterType.VisitDate:
      return props.reportIsLoading ? (
        <DisabledFilter label={i18n.translate("DATE_PERIOD_PICKER_Dates")} />
      ) : (
        VisitDateFilter()
      );
    case HubFilterType.AreasAndRegions:
      return props.reportIsLoading ? (
        <DisabledFilter
          label={i18n.translate("AREA_REGION_PICKER_Area_and_Regions")}
        />
      ) : (
        AreaAndRegionFilter()
      );
    case HubFilterType.SegmentFilter:
      return props.reportIsLoading ? (
        <DisabledFilter label={i18n.translate("SEGMENT_PICKER_LABEL")} />
      ) : (
        LocationSegmentFilter()
      );
    case HubFilterType.LocationRank:
      return props.reportIsLoading ? (
        <DisabledFilter
          label={i18n.translate("LOCATION_RANK_PICKER_Location_Rank")}
        />
      ) : (
        LocationRankFilter()
      );
    case HubFilterType.VisitType:
      return props.reportIsLoading ? (
        <DisabledFilter
          label={i18n.translate("VISIT_TYPE_PICKER_Visit_Type")}
        />
      ) : (
        VisitTypeFilter()
      );
    case HubFilterType.QuestionnaireType:
      return props.reportIsLoading ? (
        <DisabledFilter
          label={i18n.translate("QUESTIONNAIRE_TYPE_PICKER_Questionnaire_Type")}
        />
      ) : (
        QuestionnaireTypeFilter()
      );
    case HubFilterType.Questions:
      return props.reportIsLoading ? (
        <DisabledFilter label={i18n.translate("QUESTIONS_PICKER_Questions")} />
      ) : (
        QuestionsFilter()
      );
    case HubFilterType.Sections:
      return props.reportIsLoading ? (
        <DisabledFilter
          label={i18n.translate("QUESTION_SECTIONS_PICKER_Sections")}
        />
      ) : (
        QuestionSectionFilter()
      );
    case HubFilterType.QuestionCategory:
      return props.reportIsLoading ? (
        <DisabledFilter
          label={i18n.translate("QUESTION_CATEGORY_PICKER_Question_Categrory")}
        />
      ) : (
        QuestionCategoryFilter()
      );
    case HubFilterType.Benchmarks:
      return props.reportIsLoading ? (
        <DisabledFilter
          label={i18n.translate("QUESTION_BENCHMARKS_PICKER_Benchmarks")}
        />
      ) : (
        BenchmarksFilter()
      );
    case HubFilterType.NPS:
      return props.reportIsLoading ? (
        <DisabledFilter
          label={i18n.translate("QUESTION_NPS_PICKER_NetPromoter")}
        />
      ) : (
        NPSFilter()
      );
    case HubFilterType.VisitScoreRange:
      return props.reportIsLoading ? (
        <DisabledFilter
          label={i18n.translate("VISIT_SCORE_RANGE_PICKER_Visit_Score")}
        />
      ) : (
        VisitScoreFilter()
      );
    case HubFilterType.QuestionScoreRange:
      return props.reportIsLoading ? (
        <DisabledFilter
          label={i18n.translate("QUESTION_SCORE_RANGE_PICKER_Question_Score")}
        />
      ) : (
        QuestionScoreFilter()
      );
    case HubFilterType.DayOfWeek:
      return props.reportIsLoading ? (
        <DisabledFilter label={i18n.translate("DAY_OF_WEEK_PICKER_Day")} />
      ) : (
        DayOfWeekFilter()
      );
    case HubFilterType.DayPart:
      return props.reportIsLoading ? (
        <DisabledFilter label={i18n.translate("DAY_PART_PICKER_DayPart")} />
      ) : (
        DayPartFilter()
      );
    case HubFilterType.TaskCentreStatus:
      return props.reportIsLoading ? (
        <DisabledFilter
          label={i18n.translate("TASK_CENTRE_STATUS_PICKER_Status")}
        />
      ) : (
        TaskCentreStatusFilter()
      );
    case HubFilterType.AppealStatus:
      return !props.appealsFeatureActive ? (
        <></>
      ) : props.reportIsLoading ? (
        <DisabledFilter
          label={i18n.translate("TASK_CENTRE_STATUS_PICKER_Status")}
        />
      ) : (
        AppealStatusFilter()
      );
    case HubFilterType.TaskCentreCategory:
      return props.reportIsLoading ? (
        <DisabledFilter
          label={i18n.translate("TASK_CENTRE_CATEGORY_PICKER_Category")}
        />
      ) : (
        TaskCentreCategoryFilter()
      );
    case HubFilterType.TaskCentrePriority:
      return props.reportIsLoading ? (
        <DisabledFilter
          label={i18n.translate("TASK_CENTRE_PRIORITY_PICKER_Priority")}
        />
      ) : (
        TaskCentrePriorityFilter()
      );
    case HubFilterType.VisitAnalysisDepartment:
      return props.reportIsLoading ? (
        <DisabledFilter
          label={i18n.translate("ANALYSIS_TAG_VALUE_PICKER_Department")}
        />
      ) : (
        AnalysisTagsValueFilter(
          HubFilterType.VisitAnalysisDepartment,
          "Department"
        )
      );
    case HubFilterType.VisitAnalysisRoom:
      return props.reportIsLoading ? (
        <DisabledFilter
          label={i18n.translate("ANALYSIS_TAG_VALUE_PICKER_Room")}
        />
      ) : (
        AnalysisTagsValueFilter(HubFilterType.VisitAnalysisRoom, "Room")
      );
    case HubFilterType.VisitId:
      return props.reportIsLoading ? (
        <DisabledFilter label={i18n.translate("VISIT_ID_PICKER_VisitId")} />
      ) : (
        VisitIdFilter()
      );
    case HubFilterType.VisitSource:
      return props.reportIsLoading ? (
        <DisabledFilter
          label={i18n.translate("VISIT_SOURCE_PICKER_Visit_Source")}
        />
      ) : (
        VisitSourceFilter()
      );
    case HubFilterType.FeedbackCategory:
      return props.reportIsLoading ? (
        <DisabledFilter
          label={i18n.translate("ANALYSIS_TAG_VALUE_PICKER_Feedback_Category")}
        />
      ) : (
        AnalysisTagsValueFilter(
          HubFilterType.FeedbackCategory,
          "Feedback Category"
        )
      );
    case HubFilterType.FeedbackSubCategory:
      return props.reportIsLoading ? (
        <DisabledFilter
          label={i18n.translate(
            "ANALYSIS_TAG_VALUE_PICKER_Feedback_Sub_Category"
          )}
        />
      ) : (
        AnalysisTagsValueFilter(
          HubFilterType.FeedbackSubCategory,
          "Sub Category"
        )
      );
    case HubFilterType.TaskAssignee:
      return props.reportIsLoading ? (
        <DisabledFilter
          label={i18n.translate("TASK_ASSIGNEE_PICKER_Assignee")}
        />
      ) : (
        TaskAssigneeFilter()
      );
    case HubFilterType.VisitContactStatus:
      return props.reportIsLoading ? (
        <DisabledFilter
          label={i18n.translate("VISIT_CONTACT_STATUS_PICKER_Contact_Status")}
        />
      ) : (
        VisitContactStatusFilter()
      );
    case HubFilterType.LengthOfService:
      return props.reportIsLoading ? (
        <DisabledFilter
          label={i18n.translate("ANALYSIS_TAG_VALUE_PICKER_Length_of_Service")}
        />
      ) : (
        AnalysisTagsValueFilter(
          HubFilterType.LengthOfService,
          "Length of Service"
        )
      );
    case HubFilterType.BrandFilter:
      return <BrandPicker />;

    default:
      return UnknownFilterType();
  }
};

export default ReportFilter;
