import React, { Dispatch, SetStateAction, useState } from "react";
import { Button, Theme, makeStyles, useTheme } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import dayjs from "dayjs";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { i18n } from "../../../localizations";
import minMax from "dayjs/plugin/minMax";

dayjs.extend(minMax);

interface IProps {
  startDate: Date | null | undefined;
  endDate: Date | null | undefined;
  minDateDayOffset: number;
  defaultStartDateOffset: number;
  onCancel: () => void;
  onApply: (startDate: Date, endDate: Date) => void;
  maxDays: number | undefined;
  setShowMaxPeriodError: Dispatch<SetStateAction<boolean>>;
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  dateContainer: {
    margin: "10px",

    "& label": {
      color: theme.palette.grey[800],
    },
  },
  actions: {
    margin: "20px 10px 10px 10px",

    "& button": {
      float: "right",
      marginLeft: "10px",
      bottom: "8px",
    },
  },
}));

const CustomDateRangePicker = (props: IProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const maxCustomDate = dayjs().toDate();
  const minCustomDate = dayjs().add(props.minDateDayOffset, "day").toDate();

  const defaultStart = dayjs()
    .add(props.defaultStartDateOffset, "day")
    .toDate();
  const defaultEnd = dayjs().toDate();

  const [customStartDate, setCustomStartDate] = useState(
    props.startDate ? props.startDate : defaultStart
  );
  const [customEndDate, setCustomEndDate] = useState(
    props.endDate ? props.endDate : defaultEnd
  );

  const [startDateMax, setStartDateMax] = useState(
    props.endDate ? props.endDate : defaultEnd
  );
  const [endDateMin, setEndDateMin] = useState(
    props.startDate ? props.startDate : defaultStart
  );

  const [startDateHasErrors, setStartDateHasErrors] = useState(false);
  const [endDateHasErrors, setEndDateHasErrors] = useState(false);

  const onPickDate = (date: Date, compareDate: Date) => {
    if (!props.maxDays) return;
    props.setShowMaxPeriodError?.(false);

    const numDays = Math.abs(dayjs(date).diff(dayjs(compareDate), "day"));

    if (numDays > props.maxDays) {
      setStartDateHasErrors(true);
      setEndDateHasErrors(true);
      props.setShowMaxPeriodError?.(true);
    } else {
      setStartDateHasErrors(false);
      setEndDateHasErrors(false);
    }
  };

  const setStartDate = (date: MaterialUiPickersDate) => {
    if (
      date &&
      date.isValid() &&
      date.isAfter(minCustomDate) &&
      date.isBefore(customEndDate)
    ) {
      setEndDateMin(date.toDate());
      setCustomStartDate(date.toDate());
      setStartDateHasErrors(false);
      onPickDate(date.toDate(), customEndDate);
    } else {
      setStartDateHasErrors(true);
    }
  };

  const setEndDate = (date: MaterialUiPickersDate) => {
    const endDateIsAfterStartDate = !date
      ?.startOf("day")
      .isBefore(dayjs(customStartDate).startOf("day"));
    const endDateIsNotInTheFuture = !date
      ?.startOf("day")
      .isAfter(dayjs(maxCustomDate).startOf("day"));
    if (
      date &&
      date.isValid() &&
      endDateIsAfterStartDate &&
      endDateIsNotInTheFuture
    ) {
      setStartDateMax(date.toDate());
      setCustomEndDate(date.toDate());
      setEndDateHasErrors(false);
      onPickDate(date.toDate(), customStartDate);
    } else {
      setEndDateHasErrors(true);
    }
  };

  const applyChanges = () => {
    const start = dayjs(customStartDate)
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0)
      .toDate();

    const end = dayjs(customEndDate)
      .hour(23)
      .minute(59)
      .second(59)
      .millisecond(0)
      .toDate();

    props.onApply(start, end);
  };

  const applyDisabled = startDateHasErrors || endDateHasErrors;

  return (
    <>
      <div className={classes.dateContainer}>
        <KeyboardDatePicker
          disableToolbar
          autoOk
          disableFuture
          variant="inline"
          format={i18n.translate("DATE_RANGE_PICKER_Display_Format")}
          margin="normal"
          id="date-picker-start"
          label={i18n.translate("DATE_RANGE_PICKER_Start_Date")}
          maxDate={startDateMax}
          minDate={minCustomDate}
          value={customStartDate}
          onChange={setStartDate}
          KeyboardButtonProps={{
            "aria-label": i18n.translate("DATE_RANGE_PICKER_Change_Start_Date"),
          }}
        />
      </div>

      <div className={classes.dateContainer}>
        <KeyboardDatePicker
          disableToolbar
          autoOk
          disableFuture
          variant="inline"
          format={i18n.translate("DATE_RANGE_PICKER_Display_Format")}
          margin="normal"
          id="date-picker-end"
          label={i18n.translate("DATE_RANGE_PICKER_End_Date")}
          maxDate={maxCustomDate}
          minDate={endDateMin}
          value={customEndDate}
          onChange={setEndDate}
          KeyboardButtonProps={{
            "aria-label": i18n.translate("DATE_RANGE_PICKER_Change_End_Date"),
          }}
        />
      </div>
      <div className={classes.actions}>
        <Button
          onClick={applyChanges}
          variant="contained"
          color="primary"
          disabled={applyDisabled}
        >
          {i18n.translate("DATE_RANGE_PICKER_Apply")}
        </Button>
        <Button onClick={props.onCancel}>
          {i18n.translate("DATE_RANGE_PICKER_Cancel")}
        </Button>
      </div>
    </>
  );
};

export default CustomDateRangePicker;
