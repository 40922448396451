import React from "react";
import TreeItem from "@material-ui/lab/TreeItem";
import { GenericTreeViewNodeStyles } from "../styles/genericTreeViewNodeStyles";

interface IProps {
  nodeSequence: string[];
  nodeOptions: any[];
  selectedNodes: string[];
  onLeafNodeSelected: (
    event: React.ChangeEvent<HTMLInputElement>,
    disabled: boolean
  ) => void;
  onNodeSelected: (
    event: React.ChangeEvent<HTMLInputElement>,
    nodeType: string,
    disabled: boolean
  ) => void;
  leafNodeTooltipProperty?: string | undefined;
  sortNodes: boolean;
  disabledNodes?: any[];
}

interface checkedStatus {
  allChecked: boolean;
  someChecked: boolean;
}

const GenericTreeViewNodes = (props: IProps) => {
  const classes = GenericTreeViewNodeStyles();

  const renderNodes = (
    nodeIndex: number,
    parentNodeType?: string,
    parentNode?: string
  ): React.ReactNode => {
    const isLeafNode = props.nodeSequence.length <= nodeIndex + 1;
    const nodeType = props.nodeSequence[nodeIndex];

    const uniqueNodes =
      parentNodeType !== undefined
        ? getUniqueNodesByNodeTypeAndParent(
            nodeType,
            parentNode,
            parentNodeType
          )
        : getUniqueNodesByNodeType(nodeType);

    return uniqueNodes.map((node) => {
      if (node === undefined) {
        return !isLeafNode ? renderNodes(nodeIndex + 1, nodeType, node) : null;
      } else {
        const nodeId = `node:${nodeIndex}:${node}`;
        const clickHandler = isLeafNode
          ? (event: React.ChangeEvent<HTMLInputElement>) =>
              props.onLeafNodeSelected(
                event,
                props.disabledNodes?.includes(node) ?? false
              )
          : props.onNodeSelected;

        let tooltip = "";
        if (props.leafNodeTooltipProperty && isLeafNode) {
          tooltip = getTooltipContentForNode(
            node,
            nodeType,
            props.leafNodeTooltipProperty
          );
        }

        return (
          <TreeItem
            key={nodeId}
            className={`${
              nodeIndex === 0 ? classes.rootTreeItem : classes.treeItem
            }
            ${props.nodeSequence.length === 1 ? classes.singleNodeTree : ""}`}
            nodeId={nodeId}
            label={renderTreeItemContent(node, nodeType, clickHandler)}
            title={tooltip}
          >
            {!isLeafNode && renderNodes(nodeIndex + 1, nodeType, node)}
          </TreeItem>
        );
      }
    });
  };

  const getUniqueNodesByNodeType = (nodeType: string) => {
    const nodes = [...new Set(props.nodeOptions.map((o: any) => o[nodeType]))];

    if (props.sortNodes) {
      return nodes.sort();
    }

    return nodes;
  };

  const getUniqueNodesByNodeTypeAndParent = (
    nodeType: string,
    parentNode: string | undefined,
    parentNodeType: string
  ) => {
    const nodes = [
      ...new Set(
        props.nodeOptions
          .filter((f: any) => {
            return f[parentNodeType] === parentNode;
          })
          .map((o: any) => o[nodeType])
      ),
    ];

    if (props.sortNodes) {
      return nodes.sort();
    }

    return nodes;
  };

  const getTooltipContentForNode = (
    node: string,
    nodeType: string,
    tooltipProperty: string
  ) => {
    const matchedNode = props.nodeOptions.find((o) => {
      return o[nodeType] === node;
    });

    if (matchedNode) {
      return matchedNode[tooltipProperty];
    } else {
      return "";
    }
  };

  const itemIsChecked = (node: string, nodeType: string): checkedStatus => {
    let allChecked = false;
    let someChecked = false;

    const leafNodeType = props.nodeSequence[props.nodeSequence.length - 1];
    const nodeIsLeafNode = leafNodeType === nodeType;

    if (nodeIsLeafNode) {
      allChecked = props.selectedNodes.indexOf(node) > -1;
    } else {
      let allLeafNodesSelectedForNode = true;
      let someLeafNodesSelectedForNode = false;

      const releatedNodes = props.nodeOptions.filter((o: any) => {
        return o[nodeType] === node;
      });

      releatedNodes.forEach((relatedNode: any) => {
        if (props.selectedNodes.indexOf(relatedNode[leafNodeType]) === -1) {
          allLeafNodesSelectedForNode = false;
        } else {
          someLeafNodesSelectedForNode = true;
        }
      });

      allChecked = allLeafNodesSelectedForNode;
      someChecked = someLeafNodesSelectedForNode;
    }

    return { allChecked, someChecked } as checkedStatus;
  };

  const renderTreeItemContent = (
    label: string,
    type: string,
    checkboxClickHandler: (
      event: React.ChangeEvent<HTMLInputElement>,
      nodeType: string,
      disabled: boolean
    ) => void
  ) => {
    const checkStatus = itemIsChecked(label, type);
    const isDisabled =
      (props.disabledNodes?.includes(label) ||
        (props.disabledNodes &&
          props.disabledNodes.length > 0 &&
          type === props.nodeSequence[0])) ??
      false;

    const checkmarkClass =
      checkStatus.someChecked && !checkStatus.allChecked ? "partial-check" : "";

    return (
      <>
        <label className={classes.treeItemContent}>
          <input
            type="checkbox"
            value={label}
            name={label}
            onChange={(e) => checkboxClickHandler(e, type, isDisabled)}
            checked={checkStatus.allChecked}
            className={isDisabled ? "disabled" : ""}
          />
          <span className={`${classes.checkmark} ${checkmarkClass}`}></span>
          {label}
        </label>
      </>
    );
  };

  return <>{renderNodes(0)}</>;
};

export default GenericTreeViewNodes;
