import { useMemo } from "react";
import dayjs from "dayjs";
import {
  IClientPeriod,
  IPeriodHierarchy,
  IPeriodHierarchyOption,
} from "../../../../state/types/FilterOptions";

export const useCalculateTotalDaysPeriods = (
  availablePeriods: IClientPeriod[],
  maxDays?: number
) => {
  return useMemo(() => {
    const calculateTotalDays = (periods: string[]) => {
      if (!maxDays) return 0;

      const selectedPeriodsDetails = availablePeriods.filter((p) =>
        periods.includes(p.periodName)
      );

      return selectedPeriodsDetails.reduce(
        (total, p) =>
          total + dayjs(p.endDate).diff(dayjs(p.startDate), "day") + 1,
        0
      );
    };

    return calculateTotalDays;
  }, [availablePeriods, maxDays]);
};

export const useCalculateTotalDaysPeriodHierarchies = (
  availablePeriods: IPeriodHierarchyOption[],
  maxDays?: number
) => {
  const findPeriodDetailsById = (periodId: number): IPeriodHierarchy | null => {
    for (const periodGroup of availablePeriods) {
      const foundPeriod = findPeriodInHierarchy(periodId, periodGroup.periods);
      if (foundPeriod) return foundPeriod;
    }
    return null;
  };

  const findPeriodInHierarchy = (
    periodId: number,
    periods: IPeriodHierarchy[]
  ): IPeriodHierarchy | null => {
    for (const period of periods) {
      if (period.id === periodId) return period;
      if (period.children && period.children.length > 0) {
        const childResult = findPeriodInHierarchy(periodId, period.children);
        if (childResult) return childResult;
      }
    }
    return null;
  };
  return useMemo(() => {
    const calculateTotalDays = (periodIds: number[]) => {
      if (!maxDays) return 0;

      const selectedPeriodsDetails = periodIds
        .map((periodId) => findPeriodDetailsById(periodId))
        .filter(Boolean);

      if (selectedPeriodsDetails.length === 0) return 0;

      const uniquePeriods = selectedPeriodsDetails.filter(
        (period, index, self) =>
          period && self.findIndex((p) => p && p.id === period.id) === index
      );

      return uniquePeriods.reduce((total, period) => {
        const periodDays =
          dayjs(period?.endDate).diff(period?.startDate, "day") + 1;
        return total + periodDays;
      }, 0);
    };

    return calculateTotalDays;
  }, [availablePeriods, maxDays]);
};
