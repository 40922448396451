import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  MenuItem,
  Button,
  Menu,
  useTheme,
  Theme,
  makeStyles,
} from "@material-ui/core";
import dayjs from "dayjs";
import GenericTreeView from "../genericTreeView/genericTreeView";
import { IClientPeriod } from "../../../state/types/FilterOptions";
import { VisitDateSelectionType } from "../../../state/types/FilterSets";
import { i18n } from "../../../localizations";
import { useCalculateTotalDaysPeriods } from "./utils/calculateTotalDays";

interface IProps {
  availablePeriods: IClientPeriod[];
  selectedPeriods: string[];
  onCancel: () => void;
  onApply: (selection: string[], selectionType: VisitDateSelectionType) => void;
  maxDays: number | undefined;
  setShowMaxPeriodError?: Dispatch<SetStateAction<boolean>>;
}

interface IPresetOption {
  option: VisitDateSelectionType;
  matchedPeriods: string[];
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  quickOptionsContainer: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  quickOptionsButton: {
    margin: "5px",
  },
}));

const PeriodPicker = (props: IProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [selectedPreset, setSelectedPreset] = useState(
    VisitDateSelectionType.ClientPeriods
  );

  const [disabledPeriods, setDisabledPeriods] = useState<string[]>([]);
  const [selectedPeriods, setSelectedPeriods] = useState(props.selectedPeriods);

  const calculateTotalDays = useCalculateTotalDaysPeriods(
    props.availablePeriods,
    props.maxDays
  );

  useEffect(() => {
    if (!props.maxDays) return;

    const newDisabledPeriods: string[] = props.availablePeriods
      .filter((period) => {
        const tempSelectedPeriods = [...selectedPeriods];

        if (!tempSelectedPeriods.includes(period.periodName)) {
          tempSelectedPeriods.push(period.periodName);
        }
        const totalDays = calculateTotalDays(tempSelectedPeriods);
        return totalDays > props.maxDays!;
      })
      .map((p) => p.periodName);
    if (newDisabledPeriods.length === 0) props.setShowMaxPeriodError?.(false);

    setDisabledPeriods(newDisabledPeriods);
  }, [selectedPeriods, props.availablePeriods, props.maxDays]);

  useEffect(() => {
    if (!props.maxDays) return;
    if (calculateTotalDays(props.selectedPeriods) > props.maxDays) {
      const allPeriods = props.availablePeriods.map((p) => p.periodName);
      const newSelectedPeriods = [];

      for (const period of allPeriods) {
        if (
          calculateTotalDays([...newSelectedPeriods, period]) <= props.maxDays
        ) {
          newSelectedPeriods.push(period);
        } else {
          break;
        }
      }

      setSelectedPeriods(newSelectedPeriods);
    }
  }, [props.selectedPeriods]);

  const handlePresetSelection = (selection: VisitDateSelectionType): void => {
    setSelectedPreset(selection);

    const options = populatePresetOptions();
    const matchedSelectionEntry = options.find((o) => o.option === selection);

    if (matchedSelectionEntry) {
      const s = matchedSelectionEntry.matchedPeriods.map((p) => `${p}`);

      const totalDays = calculateTotalDays(s);
      if (!props.maxDays || totalDays <= props.maxDays) {
        setSelectedPeriods(s);
      }
    }

    handleQuickOptionsMenuClose();
  };

  const populatePresetOptions = () => {
    const now = dayjs();
    const orderedPeriods = props.availablePeriods.sort((a, b) => {
      return a.endDate > b.endDate ? -1 : a.endDate < b.endDate ? 1 : 0;
    });

    const completedPeriods = orderedPeriods.filter((p) => {
      return dayjs(p.endDate).isBefore(now);
    });

    const presetOptions: IPresetOption[] = [];
    const mostRecentIsActive =
      dayjs(orderedPeriods[0].endDate).isAfter(now) &&
      (!props.maxDays ||
        calculateTotalDays([orderedPeriods[0].periodName]) < props.maxDays);

    if (mostRecentIsActive) {
      presetOptions.push({
        option: VisitDateSelectionType.CurrentPeriod,
        matchedPeriods: [orderedPeriods[0].periodName],
      } as IPresetOption);
    }

    if (
      completedPeriods.length > 0 &&
      (!props.maxDays ||
        calculateTotalDays([completedPeriods[0].periodName]) < props.maxDays)
    ) {
      presetOptions.push({
        option: VisitDateSelectionType.LastCompletePeriod,
        matchedPeriods: [completedPeriods[0].periodName],
      } as IPresetOption);
    }

    if (
      completedPeriods.length > 1 &&
      (!props.maxDays ||
        calculateTotalDays([
          completedPeriods[0].periodName,
          completedPeriods[1].periodName,
        ]) < props.maxDays)
    ) {
      presetOptions.push({
        option: VisitDateSelectionType.LastTwoCompletePeriods,
        matchedPeriods: [
          completedPeriods[0].periodName,
          completedPeriods[1].periodName,
        ],
      } as IPresetOption);
    }

    if (
      completedPeriods.length > 2 &&
      (!props.maxDays ||
        calculateTotalDays([
          completedPeriods[0].periodName,
          completedPeriods[1].periodName,
          completedPeriods[2].periodName,
        ]) < props.maxDays)
    ) {
      presetOptions.push({
        option: VisitDateSelectionType.LastThreeCompletePeriods,
        matchedPeriods: [
          completedPeriods[0].periodName,
          completedPeriods[1].periodName,
          completedPeriods[2].periodName,
        ],
      } as IPresetOption);
    }

    const currentYearPeriods = orderedPeriods.filter((o) => {
      return o.periodYearName === orderedPeriods[0].periodYearName;
    });

    if (
      currentYearPeriods.length > 0 &&
      (!props.maxDays ||
        calculateTotalDays(currentYearPeriods.map((p) => p.periodName)) <
          props.maxDays)
    ) {
      presetOptions.push({
        option: VisitDateSelectionType.CurrentYearToDate,
        matchedPeriods: currentYearPeriods.map((p) => p.periodName),
      } as IPresetOption);
    }

    const years = orderedPeriods
      .filter((p) => p.periodYearName !== orderedPeriods[0].periodYearName)
      .map((x) => x.periodYearName);

    const previousYearPeriods =
      years.length > 0
        ? orderedPeriods.filter((x) => x.periodYearName === years[0])
        : [];

    if (
      previousYearPeriods.length > 0 &&
      (!props.maxDays ||
        calculateTotalDays(previousYearPeriods.map((p) => p.periodName)) <
          props.maxDays)
    ) {
      presetOptions.push({
        option: VisitDateSelectionType.PreviousYear,
        matchedPeriods: previousYearPeriods.map((p) => p.periodName),
      } as IPresetOption);
    }

    return presetOptions;
  };

  const [quickOptionsMenuAnchor, setQuickOptionsMenuAnchor] = React.useState<
    HTMLButtonElement | undefined
  >(undefined);

  const handleQuickOptionsMenuOpen = (target: HTMLButtonElement) => {
    setQuickOptionsMenuAnchor(target);
  };

  const handleQuickOptionsMenuClose = () => {
    setQuickOptionsMenuAnchor(undefined);
  };

  const presetOptions = populatePresetOptions();

  const selectAllPeriods = () => {
    const allPeriods = props.availablePeriods.map((p) => p.periodName);
    const newSelectedPeriods = [...selectedPeriods];

    for (const period of allPeriods) {
      if (
        (!props.maxDays ||
          calculateTotalDays([...newSelectedPeriods, period]) <=
            props.maxDays) &&
        !selectedPeriods.includes(period)
      ) {
        newSelectedPeriods.push(period);
      } else {
        break;
      }
    }

    setSelectedPeriods(newSelectedPeriods);
  };

  const clearAllPeriods = () => {
    setSelectedPeriods([]);
    props.setShowMaxPeriodError?.(false);
  };

  const nodeSelectionChanged = (node: string) => {
    let newSelectedPeriods = [...selectedPeriods];

    if (newSelectedPeriods.includes(node)) {
      newSelectedPeriods = newSelectedPeriods.filter((p) => p !== node);
    } else {
      // branch node
      if (!props.availablePeriods.find((p) => p.periodName === node)) {
        const leafPeriods = props.availablePeriods
          .filter((p) => p.periodYearName === node)
          .map((p) => p.periodName);

        newSelectedPeriods = leafPeriods.reduce((acc, period) => {
          const potentialTotalDays = calculateTotalDays([...acc, period]);
          return !props.maxDays || potentialTotalDays <= props.maxDays
            ? [...acc, period]
            : acc;
        }, newSelectedPeriods);
      } else {
        // leaf node
        newSelectedPeriods.push(node);
      }
    }

    const totalDays = calculateTotalDays(newSelectedPeriods);

    if (!props.maxDays || totalDays <= props.maxDays) {
      setSelectedPeriods(newSelectedPeriods);
      setSelectedPreset(VisitDateSelectionType.ClientPeriods);
    }
  };

  const applyPeriodSelection = () => {
    props.onApply(selectedPeriods, selectedPreset);
  };

  return (
    <>
      <div className={classes.quickOptionsContainer}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.quickOptionsButton}
          onClick={(e) => handleQuickOptionsMenuOpen(e.currentTarget)}
        >
          {i18n.translate("PERIOD_PICKER_Preset_Selections")}
        </Button>
        <Menu
          anchorEl={quickOptionsMenuAnchor}
          keepMounted
          open={Boolean(quickOptionsMenuAnchor)}
          onClose={handleQuickOptionsMenuClose}
        >
          {presetOptions.map((o) => {
            return (
              <MenuItem
                key={o.option}
                value={o.option}
                onClick={() => handlePresetSelection(o.option)}
              >
                {i18n.translate(
                  `PERIOD_PICKER_OPTION_${VisitDateSelectionType[
                    o.option
                  ].toString()}`
                )}
              </MenuItem>
            );
          })}
        </Menu>
      </div>

      <GenericTreeView
        filterPlaceholderText={i18n.translate(
          "PERIOD_PICKER_OPTION_Filter_Periods"
        )}
        selectedNodes={selectedPeriods}
        nodeOptions={props.availablePeriods}
        nodeSequence={["periodYearName", "periodName"]}
        expandAllByDefault={true}
        applySelectionCallback={applyPeriodSelection}
        cancelSelectionCallback={props.onCancel}
        nodeSelectedCallback={nodeSelectionChanged}
        leafNodeTooltipProperty="displayDate"
        sortNodes={false}
        disabledNodes={disabledPeriods}
        clearSelectedNodesCallback={clearAllPeriods}
        selectAllNodesCallback={selectAllPeriods}
        selectedDisabledNodeCallback={props.setShowMaxPeriodError}
      />
    </>
  );
};

export default PeriodPicker;
